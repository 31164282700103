import React, { useEffect } from "react";

const MeetingScheduler = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
      <div
        className="meetings-iframe-container"
        data-src="https://meetings-eu1.hubspot.com/dwayne-engelbrecht?embed=true"
      ></div>
  );
};

export default MeetingScheduler;

