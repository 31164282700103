import React from 'react';
import { Row, Col, Container } from 'react-bootstrap-v5';

function About(props) {
  return (
    <div className='mxr__benifits mxr__section mxr__hireus aboutvr-section'>
      <Container className='inneraboutvr'>
        {props.banner.map((list, index) => (
          <>
            <Row key={`benefit-${index}`}>
              <Col className='' md={6} sm={12}>
                <div className='Vr360Img'>{list.images}</div>
              </Col>
              <Col md={6} sm={12}>
                <h3 className='mxr__section-title textUppercase'>
                  {list.heading}
                </h3>
                {list.pharagraphs.map((read, index) => (
                  <p className='vr360Para' key={`benefitp-${index}`}>
                    {read}
                  </p>
                ))}
              </Col>
            </Row>
          </>
        ))}
      </Container>
    </div>
  );
}

export default About;
