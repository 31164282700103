import React from 'react';
import './hireus.css';
import { Container, Row, Col, Card } from 'react-bootstrap-v5';
import Customer from '../../../assets/images/Customer-centricity.svg';
import Project from '../../../assets/images/ProjectM.svg';
import Time from '../../../assets/images/TimeEffi.svg';

const HireUs = () => {
  const initialCards = [
    {
      title: 'Innovation-Driven',
      imgSrc: Project,
      description: `
  We use the latest advancements in technology to create solutions that are both creative and effective, directly benefiting your organisation.`,
    },
    {
      title: 'Client-Centric Approach',
      imgSrc: Customer,
      description: `We listen closely to understand your goals and deliver tailored solutions that provide measurable results, ensuring your success.`,
    },
    {
      title: 'Cost-Effective Excellence',
      imgSrc: Time,
      description: `With a highly skilled development team, we deliver high-quality solutions at competitive prices, ensuring you receive maximum value.`,
    },
  ];

  return (
    <div className='mxr__features mxr__section'>
      <Container>
        <h2 className='mxr__section-title text-center textUppercase'>
          Why Choose MXR?
        </h2>

        <Row>
          {initialCards.map((card, index) => (
            <Col key={index} sm={12} md={4} lg={4}>
              <Card>
                <img
                  className='whyChooseIcon'
                  src={card.imgSrc}
                  alt={card.title}
                />
                <Card.Title className='textUppercase'>{card.title}</Card.Title>
                <Card.Body>
                  <Card.Text>{card.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default HireUs;
