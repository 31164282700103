export const menuItems = [
  {
    title: 'Home',
    link: '/',
  },
  {
    title: 'About',
    link: '/about',
  },
  {
    title: 'Solutions',
    submenu: [
      {
        title: 'Generative AI',
        link: '/generative-ai',
      },

      {
        title: 'Virtual Reality',
        link: '/virtual-reality',
      },
      {
        title: 'Mixed Reality',
        link: '/mixed-reality',
      },
      {
        title: 'Augmented Reality',
        link: '/augmented-reality',
      },
      {
        title: 'VR 360',
        link: '/vr-360',
      },
      {
        title: 'Immersive Workspaces and Rooms',
        link: '/immersive-rooms',
      },
      {
        title: 'Web Design and Development',
        link: '/web-design-and-development',
      },

      {
        title: 'Mobile App Development',
        link: '/mobile-app-development',
      },
      {
        title: 'Research and Innovation',
        link: '/research-and-innovation',
      },

      {
        title: 'E-Learning',
        link: '/e-learning',
      },
    ],
  },
  {
    title: 'Resource Centre',
    submenu: [
      {
        title: 'Blog',
        link: '/blog',
      },
      // {
      //   title: 'Events',
      //   link: '/events',
      // },
      // {
      //   title: 'Case Study',
      //   link: '/Casestudy',
      // },
      {
        title: 'Case Study',
        link: '/AllCase-Studies',
      },
    ],
  },
  {
    title: 'Products',
    submenu: [
      {
        title: 'Insight XR',
        link: '/insightxr',
      },
      // {
      //   title: 'Examwizz',
      //   link: '/examwizz',
      // },
    ],
  },

  {
    title: 'Contact',
    link: '/contact',
  },

  // {
  //   title: 'Meeting',
  //   link: '/meeting',
  // },
];
