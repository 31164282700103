import React from 'react';
import { Container, Tab, Row, Col, Nav } from 'react-bootstrap-v5';
import engage from '../../assets/icons/whatwecando2/vr33.jpg';
import trusted from '../../assets/icons/whatwecando2/vrvr.jpg';
import our from '../../assets/icons/whatwecando2/non-immersive2.jpg';
import maximise from '../../assets/icons/whatwecando2/vr22.jpg';

import manage from '../../assets/icons/whatwecando2/content2.jpg';
import immersive from '../../assets/icons/whatwecando2/fully2.jpg';
import cost from '../../assets/icons/whatwecando2/cost2.jpg';
import vrExp from '../../assets/icons/whatwecando2/exp2.jpg';
import './vr.css';
const WhatWeCanDoo = () => {
  return (
    <div className='mxr__whatwecandoo mxr__section vertical_tabs'>
      <Container>
        {/* <h3 className='mxr__section-title'>WHAT WE CAN DO FOR YOU</h3> */}
        <Tab.Container
          id='left-tabs-example'
          defaultActiveKey='training'
          className=''
        >
          <Row>
            <Col md={6} sm={12}>
              <Nav variant='pills' className='flex-column'>
                <Nav.Item>
                  <Nav.Link eventKey='training'>What is VR Training?</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='product'>
                    What is Virtual Reality and How Does It Work?
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='virtual'>
                    Non-Immersive Virtual Reality{' '}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='visiual'>
                    Semi-Immersive Virtual Reality{' '}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='design'>
                    Fully-Immersive Virtual Reality{' '}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='cost'>
                    How Much Does VR Training Cost?{' '}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='vrex'>
                    Finding the Right VR Experience{' '}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='vrcontent'>
                    Manage Your VR Content{' '}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={6} sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey='training'>
                  <div>
                    <img className='whatwecan' src={engage} alt='VR Training' />
                    <p>
                      Virtual Reality (VR) has become a major buzzword in recent
                      years, and for good reason. Advancements in VR technology
                      have not only created new job opportunities but also
                      significantly impacted workplaces, schools, and healthcare
                      systems. VR has improved the efficiency and effectiveness
                      of training across many industries and has increased
                      connectivity and productivity in the workplace. With the
                      growing awareness of VR and its applications, many
                      organisations are considering incorporating VR into their
                      training programmes.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='product'>
                  <div>
                    <img
                      className='whatwecan'
                      src={trusted}
                      alt='Virtual Reality work'
                    />
                    <p>
                      Virtual Reality (VR) is a subset of the extended reality
                      (XR) spectrum, characterised by fully immersing users in a
                      digital environment. Unlike augmented reality (AR), which
                      overlays digital information onto the physical world, VR
                      replaces the physical world entirely with a virtual one.
                      The key components of VR include immersion, sensory
                      feedback, and interactivity. Using sensors on a headset, a
                      play mat, or gloves, VR systems capture user movements and
                      translate them into the virtual space. The VR headset then
                      orients the user within this space, allowing them to
                      interact with digital sights, sounds, and objects,
                      creating a highly immersive experience.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='virtual'>
                  <div>
                    <img
                      className='whatwecan'
                      src={our}
                      alt=' Non-Immersive '
                    />
                    <p>
                      Non-immersive virtual reality refers to technology that
                      doesn't require any additional equipment to interact with.
                      For example, a television programme in which the viewer
                      gets to decide the fate of the main character, like Bear
                      Grylls' interactive Netflix show, You vs. Wild. It holds
                      almost no real immersion into the video environment, yet
                      the user still interacts and controls the way the
                      character interacts with the environment. This type of
                      virtual reality doesn't need a headset, sensory gloves, or
                      mat to use. Non-immersive VR has been used as a
                      therapeutic tool for improving symptoms of disorders like
                      Alzheimer's and Parkinson's by helping practise fine motor
                      movements. In this scenario, the patient would use a
                      joystick or other control to interact.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='visiual'>
                  <div>
                    <img
                      className='whatwecan'
                      src={maximise}
                      alt=' Semi-immersive'
                    />
                    <p>
                      Semi-immersive virtual reality refers to experiences where
                      the user interacts with a virtual environment with some
                      senses (but not all) cut off, while still being aware of
                      the physical environment around them. It offers some
                      aspects of a fully immersive VR experience, but not all.
                      For instance, a flight simulation that enables the pilot
                      to practise flight manoeuvres using head-mounted displays
                      while still being aware of their surroundings is a
                      semi-immersive VR experience. In construction, a
                      contractor can use a head-mounted display to walk around
                      the physical building site and see the fully realised
                      version of the project in real time before it's fully
                      built. The contractor can walk around the site because
                      they can still see what's really there, but the overlay of
                      information shows a different image where necessary.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='design'>
                  <div>
                    <img
                      className='whatwecan'
                      src={immersive}
                      alt=' Fully-immersive '
                    />
                    <p>
                      Fully-immersive virtual reality is the most extreme of the
                      three and involves total immersion into a digital
                      environment, completely cutting off the physical world. In
                      this instance, the user would have visual and audio
                      stimuli coming from the headset.In some cases, a physical
                      representation of the environment that mimics the
                      movements of the virtual items they interact with is added
                      to the VR experience. This could include seats, objects,
                      pools, and heated or cooled rooms. Fully-immersive VR can
                      be used for exposure therapy, guided tours in museums, or
                      water therapy for pain management, where adding a VR
                      component can enhance the therapeutic effects.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='cost'>
                  <div>
                    <img
                      className='whatwecan'
                      src={cost}
                      alt='increasingly recognised'
                    />
                    <p>
                      Virtual reality is increasingly recognised as a
                      cost-effective training strategy, offering long-term
                      savings by accelerating onboarding, reducing turnover,
                      improving performance, and minimising safety incidents.
                      The cost of VR training varies based on the level of
                      customisation and the complexity of the content. While
                      there is no fixed price, here are some general estimates:
                      Customised 360 VR Training Materials: £20,000 to £75,000
                      or more Customised Full VR Content: £50,000 to £150,000 or
                      more Several factors influence the cost of VR training,
                      including the specific requirements of the training
                      programme, the complexity of the virtual environments, and
                      the level of interactivity needed. As we develop solutions
                      to meet your needs, we will work closely with you to
                      determine the most effective and budget-friendly approach.{' '}
                      <br />
                      <b>Contact Us to Learn More About VR Training Costs.</b>
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='vrex'>
                  <div>
                    <img
                      className='whatwecan'
                      src={vrExp}
                      alt='VR experience '
                    />
                    <p>
                      Choosing the right type of VR experience depends on the
                      specific training requirements and the desired level of
                      immersion. Fully-immersive VR is ideal for high-stakes
                      training such as exposure therapy or emergency response
                      simulations, where complete immersion is necessary.
                      Semi-immersive VR works well for scenarios like flight
                      simulation or architectural walkthroughs, where
                      interaction with both the virtual and physical
                      environments is beneficial. Non-immersive VR is suitable
                      for less interactive training, such as basic skills
                      development or therapeutic exercises. Understanding the
                      various VR options and their applications can help
                      organisations implement the most effective VR training
                      solutions, enhancing learning outcomes and operational
                      efficiency. Virtual reality is reshaping training and
                      development across industries, offering new possibilities
                      for immersive, interactive, and impactful learning
                      experiences.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='vrcontent'>
                  <div>
                    <img
                      className='whatwecan'
                      src={manage}
                      alt=' Investing in training'
                    />
                    <p>
                      Investing in training is no small feat. At MXR we can help
                      you manage your content and report results with ease.{' '}
                      <br />
                      <b>
                        InsightXR: Your XR Training Management Solution{' '}
                      </b>{' '}
                      <br /> InsightXR is our cutting-edge XR training content
                      management system that simplifies your training
                      operations. With InsightXR, you can efficiently assign,
                      track, and manage training activities globally from one
                      intuitive platform.
                    </p>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </div>
  );
};

export default WhatWeCanDoo;
