import React from 'react';
import {
  Features,
  // Solution,
  Process,
  // Promise,
  // Industries,
  Banner,
  // Stacks,
  // Cta2,
  Research,
  // Clients,
  Stats,
} from '../../components';
import AboutUs from '../../components/About-us';
import { Helmet } from 'react-helmet';
import Sticky from '../../components/stickyelements/Sticky';
// import CaseStudies from '../../components/CaseStudies/caseStudies';
import TrustedBy from '../../components/trustedBy/trusted';
import Industries from '../../components/industries/Industries';
import Cta2 from '../../components/cta2/Cta2';
import Intro from '../../components/homeIntro/Intro';
const Home = () => {
  return (
    <>
      <Helmet>
        <title>AR,VR and MR Company in UK - MXR</title>
        <meta
          name='description'
          content='MXR is UK based software development company delivering reliable and in-budget solutions for AR, VR, MR, Web and Mobile App development to clients globally.'
        />
      </Helmet>
      <Banner />
      <Sticky />
      <Features id='features-section' />
      <Intro />
      <AboutUs />
      <Research />
      <Process title='WE SIMPLIFY SOFTWARE DEVELOPMENT ' />
      {/* <CaseStudies /> */}
      <Cta2 />
      <Industries />
      <Stats />
      <TrustedBy />
    </>
  );
};

export default Home;
