import React from 'react';
import './features.css';
import { Container, Card, Col, Row } from 'react-bootstrap-v5';

// import vr360 from '../../assets/icons/SolutionsOverview/360-03.svg';
import immersive from '../../assets/icons/SolutionsOverview/immersive-room2.svg';
import augumented from '../../assets/icons/SolutionsOverview/AR.svg';
import virtual from '../../assets/icons/SolutionsOverview/VR.svg';
import mixed from '../../assets/icons/SolutionsOverview/MR.svg';
import platform from '../../assets/icons/SolutionsOverview/Platform Development-03.svg';
import Elearning from '../../assets/icons/SolutionsOverview/E-Learning-03.svg';

import { Link } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';

const initialCards = [
  {
    title: 'IMMERSIVE ROOMS',
    imgSrc: immersive,
    description: `
Immersive rooms are specially designed spaces that use advanced technology, including large screens, projection mapping, sound systems.`,
    linkTo: '/immersive-rooms',
  },
  {
    title: 'MIXED REALITY',
    imgSrc: mixed,
    description: `Blend the physical and digital worlds to create
  interactive, impactful learning and training
  experiences`,
    linkTo: '/mixed-reality',
  },
  {
    title: 'VIRTUAL REALITY',
    imgSrc: virtual,
    description: `Deliver cutting-edge VR solutions, from VR 360
  to fully immersive environments, tailored to
  your needs.`,
    linkTo: '/virtual-reality',
  },
  {
    title: 'AUGMENTED REALITY',
    imgSrc: augumented,
    description: `Transform learning and training with immersive
AR experiences that engage and educate.`,
    linkTo: '/augmented-reality',
  },

  // {
  //   title: 'IMMERSIVE ROOMS',
  //   imgSrc: Immersive,
  //   description: `Our immersive rooms are specially designed spaces where digital and physical worlds converge to create high-fidelity simulations.`,
  //   linkTo: '/mixed-reality',
  // },
  // {
  //   title: 'ARTIFICIAL INTELLIGENCE',
  //   imgSrc: AI,
  //   description: `MXR offers adaptable and scalable AI solutions that enable businesses to boost productivity, make data-driven choices and stay ahead in the rapidly evolving technological landscape. `,
  //   linkTo: '/mixed-reality',
  // },
  // {
  //   title: 'DIGITAL TWINNING',

  //   imgSrc: mixed,
  //   description: `With the help of our digital twinning services, businesses can track, evaluate, and optimize their operations in real-time by generating virtual copies of their real assets, systems, or procedures. `,
  //   linkTo: '/mixed-reality',
  // },

  {
    title: 'E-LEARNING',
    imgSrc: Elearning,
    description: `Create engaging, interactive e-learning content
aligned with UK curriculum standards and
awarding body requirements.`,
    linkTo: '/e-learning',
  },

  {
    title: 'PLATFORM DEVELOPMENT',
    imgSrc: platform,
    description: `Design and develop bespoke platforms for LMS,
workforce development, and data analytics,
ensuring seamless integration.`,
    linkTo: '/web-design-and-development',
  },
  // Add more card data as needed
];

const Features = () => {
  //const [visibleCards, setVisibleCards] = useState(initialCards.slice(0, 6));
  //const [allCardsLoaded, setAllCardsLoaded] = useState(false);

  // const loadMore = () => {
  //   const startIndex = visibleCards.length;
  //   const endIndex = startIndex + 4;
  //   const nextCards = initialCards.slice(startIndex, endIndex);
  //   setVisibleCards([...visibleCards, ...nextCards]);

  //   // Check if there are more cards to load
  //   if (endIndex >= initialCards.length) {
  //     setAllCardsLoaded(true);
  //   }
  // };

  return (
    <div id='features-section' className='mxr__features mxr__section'>
      <Container>
        <h2 className='mxr__section-title text-center solutionOverviewTittle'>
          SOLUTIONS OVERVIEW
        </h2>

        <Row>
          {initialCards.map((card, index) => (
            <Col key={index} sm={12} md={4} lg={4}>
              <Card>
                <img
                  className='solutionIcons'
                  src={card.imgSrc}
                  alt={card.title}
                />
                <Card.Title>{card.title}</Card.Title>
                <Card.Body>
                  <Card.Text>{card.description}</Card.Text>
                </Card.Body>
                <Link to={card.linkTo} className='features_text_color'>
                  Explore <IoIosArrowForward />
                </Link>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Features;
