import React from 'react';
import './Statistics.css';
import { Container } from 'react-bootstrap-v5';

import icon1 from '../../assets/icons/statVR/Learning.svg';
import icon2 from '../../assets/icons/statVR/Safe-working.svg';
import icon3 from '../../assets/icons/statVR/Decrease-in-time.svg';
import icon4 from '../../assets/icons/statVR/Reduction.svg';
const statisticsData = [
  {
    percentage: '42%',
    text: 'Enhancement in cognitive abilities and memory retention',
    icon: icon1,
  },
  {
    percentage: '45%',
    text: 'Increased workplace safety through immersive hazard simulations',
    icon: icon2,
  },
  {
    percentage: '37%',
    text: 'Faster completion of complex tasks due to hands-on VR practice',
    icon: icon3,
  },
  {
    percentage: '55%',
    text: 'Significant reduction in total training time, enabling quicker onboarding',
    icon: icon4,
  },
  // {
  //   percentage: '40%',
  //   text: 'Improvement in spatial learning and memory recall',
  //   icon: icon1,
  // },
  // { percentage: '43%', text: 'Safer working conditions', icon: icon2 },
  // {
  //   percentage: '38%',
  //   text: 'Decrease in time to complete tasks',
  //   icon: icon3,
  // },
  // { percentage: '54%', text: 'Reduction in training time', icon: icon4 },
];

const Statistics = () => {
  return (
    <div className='mxr__section'>
      <div className='statistics-section'>
        <Container>
          <h2 className='textUppercase mxr__section-title text-center'>
            Key statistics on the benefits of VR training
          </h2>
          <div className='statistics-container'>
            {statisticsData.map((stat, index) => (
              <div className='stat-item' key={index}>
                <img
                  src={stat.icon}
                  alt={`icon-${index}`}
                  className='stat-icon'
                />
                <h3>{stat.percentage}</h3>
                <p>{stat.text}</p>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Statistics;
