import React from 'react';
import { Container, Tab, Row, Col, Nav } from 'react-bootstrap-v5';
import training from '../../assets/icons/whatwecando/simulation33.jpg';
import markerbased from '../../assets/icons/whatwecando/markerbased2.jpg';
import product from '../../assets/icons/whatwecando/product33.jpg';
import virtual from '../../assets/icons/whatwecando/tours22.jpg';
import visiual from '../../assets/icons/whatwecando/presentation22.jpg';
import markerless from '../../assets/icons/whatwecando/markerless-AR2.jpg';
import location from '../../assets/icons/whatwecando/location2.jpg';
import interior from '../../assets/icons/whatwecando/realestate22.jpg';
import reality from '../../assets/icons/whatwecando/games2.jpg';
import web from '../../assets/icons/whatwecando/web-AR2.jpg';

const WhatWeCanDo = () => {
  return (
    <div className='mxr__whatwecando mxr__section vertical_tabs'>
      <Container>
        <h3 className='mxr__section-title text-center'>
          WHAT WE CAN DO FOR YOU
        </h3>
        <Tab.Container id='left-tabs-example' defaultActiveKey='cate1'>
          <Row>
            <Col md={6} sm={12}>
              <Nav variant='pills' className='flex-column'>
                <Nav.Item>
                  <Nav.Link eventKey='cate1'>
                    Marker-Based Augmented Reality
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='cate2'>
                    Markerless Augmented Reality
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='cate3'>
                    Location-Based Augmented Reality
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='training'>
                    Augmented Training and Simulations
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='product'>
                    Augmented Product Configurators
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='virtual'>
                    Augmented Virtual Tours
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='visiual'>
                    Augmented Visual Presentations
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='design'>
                    AR for Interior Design and Real Estate
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='reality'>
                    Augmented Reality Games
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='web'>Web AR</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={6} sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey='cate1'>
                  <div>
                    <img
                      className='whatwecan'
                      src={markerbased}
                      alt='  Marker-Based Augmented Reality'
                    />
                    <p>
                      Marker-based AR relies on visual markers, such as QR codes
                      or images, to trigger the display of digital content. When
                      the device's camera recognises the marker, it overlays the
                      digital information onto the physical object. This type of
                      AR is commonly used in educational tools, product
                      demonstrations, and interactive marketing.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='cate2'>
                  <div>
                    <img
                      className='whatwecan'
                      src={markerless}
                      alt='  Markerless Augmented Reality'
                    />
                    <p>
                      Markerless AR does not require specific visual markers to
                      trigger the augmentation. Instead, it uses the device's
                      sensors and algorithms to understand the environment and
                      place digital content within it. This type of AR is
                      versatile and widely used in various applications, such as
                      gaming, interior design, and interactive training.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='cate3'>
                  <div>
                    <img
                      className='whatwecan'
                      src={location}
                      alt='     Location-Based Augmented Reality'
                    />
                    <p>
                      Location-based AR uses GPS, accelerometers, and other
                      location-based sensors to overlay digital information onto
                      specific geographical locations. This type of AR is used
                      in applications like navigation, tourism, and
                      location-based marketing, where users can access
                      contextual information based on their current location.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='training'>
                  <div>
                    <img
                      className='whatwecan'
                      src={training}
                      alt='Augmented Training and Simulations'
                    />
                    <p>
                      AR education apps are becoming increasingly popular in
                      visualising complex information. We have developed several
                      augmented reality training apps for education, including
                      AR learning apps for auto repair, wheel replacement, and
                      water skiing.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='product'>
                  <div>
                    <img
                      className='whatwecan'
                      src={product}
                      alt='  Augmented Product Configurators'
                    />
                    <p>
                      Customisation is one of the biggest selling points in
                      e-commerce and lots of other industries. With AR
                      configurators, businesses and consumers can visualise and
                      modify a product, look at it in more detail, and test its
                      functions. As one of the top augmented reality companies
                      we have created AR configurators to suit different
                      business needs, as we can do for you.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='virtual'>
                  <div>
                    <img
                      className='whatwecan'
                      src={virtual}
                      alt='AUGMENTED VIRTUAL TOURS'
                    />
                    <p>
                      We use augmented reality to add some unique interactive
                      elements to locations to enhance the exploration process
                      and bring a new experience to the viewer. This can be very
                      helpful in case you want to engage the audience in the
                      exploration process and deliver an enhanced experience of
                      interaction with objects.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='visiual'>
                  <div>
                    <img
                      className='whatwecan'
                      src={visiual}
                      alt='AUGMENTED VISUAL PRESENTATIONS'
                    />
                    <p>
                      When you need to showcase a product, few things are more
                      impressive than presenting it digitally via augmented
                      reality. Our team will capture the structure, appearance,
                      and other characteristics of your product with great
                      accuracy, and make sure that the AR model is just as
                      distinguished as the original, and accessible on different
                      platforms, including mobile AR.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='design'>
                  <div>
                    <img
                      className='whatwecan'
                      src={interior}
                      alt='AR FOR INTERIOR DESIGN AND REAL ESTATE'
                    />
                    <p>
                      We are a leading company in the development of markerless
                      AR solutions such as interior design apps. Starting from
                      high poly models creation and perfectly accurate surface
                      detection to uniquely useful feature integration and app
                      deployment to any platform that supports AR we are ready
                      to create a fully immersive experience that will convince
                      users of your quality and professional approach.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='reality'>
                  <div>
                    <img className='whatwecan' src={reality} alt='game' />
                    <p>
                      Over the past several years, the game industry has been
                      reinvigorated with some of the best AR games ever
                      developed, and the augmented reality games market is
                      stronger than ever.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='web'>
                  <div>
                    <img className='whatwecan' src={web} alt='web' />
                    <p>
                      Applying our longstanding experience with all forms of AR,
                      we can create unparalleled experiences for the web. These
                      applications will be accessible on all modern movie
                      devices and take advantage of hardware without promoting
                      downloads of standalone apps. Our WebAR apps can be a
                      valuable addition to your website and a boon to your
                      business.
                    </p>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </div>
  );
};

export default WhatWeCanDo;
