import React from 'react';
import './nhsCase.css';
import { Container, Row, Col, Card } from 'react-bootstrap-v5';
import {
  FaUserMd,
  FaStethoscope,
  FaUserNurse,
  FaToolbox,
  FaLightbulb,
  FaLaptopCode,
} from 'react-icons/fa';
import { Innerbanner } from '../../components';
import Readytotalk from '../../components/readytotalk/Readytotalk';
import cas from '../../assets/All-Images/case.png';
import vrWalk from '../../assets/images/Case-study3.png';
import vrVideo from '../../assets/videos/hospital-video.mp4';
const readytotalk = {
  img: 'MR.jpg',
  title: 'Get in Touch',
  text: 'Contact us today to learn more about how MXR can partner with your institution to revolutionise the way you deliver education.',
  button: 'Contact us',
};
function NhsCase() {
  const bannerdata = {
    img: 'nhsCaseBanner.jpg',
    title: 'Engaging the Next Generation with Career Pathways',
    text: '',
  };
  return (
    <>
      <Innerbanner banner={bannerdata} />
      <Container>
        <div className='gutters'>
          {/* <header className="page-header group">
            <div className="logo">
              <h1>James L Cobb</h1>
              <h2>User Experience Designer</h2>
            </div>
            <nav className="main-nav">
              <ul>
                <li className="active">
                  <a href="#">Work</a>
                </li>
                <li>
                  <a href="#">About</a>
                </li>
              </ul>
            </nav>
            <div className="border">
              <a href="https://imgur.com/JNes3J4">
                <img
                  src="https://i.imgur.com/JNes3J4.jpg"
                  alt="Hosted by imgur.com"
                />
              </a>
            </div>
          </header> */}

          <main className='mb-5 mt-5'>
            <article>
              <Container>
                <div className='case__section-title'>
                  <h2>
                    NHS VR Walkthrough: Engaging the Next Generation with Career
                    Pathways.
                  </h2>
                </div>
                <Row>
                  <Col>
                    <figure className='aside'>
                      {/* <div className='module'> */}
                      <ul>
                        <p>
                          <strong className='text-black'> Client: </strong>NHS
                        </p>
                        <p>
                          <strong className='text-black'> Project: </strong>
                          Provide young people with immersive, real-world
                          experiences
                        </p>
                        <p>
                          <strong className='text-black'> Industry: </strong>
                          Health and Care
                        </p>
                        <p>
                          <strong className='text-black'> Duration: </strong>3
                          Months{' '}
                        </p>
                        <p>
                          <strong className='text-black'> Technology: </strong>
                          UI/UX designers, Graphic designers, Web designers{' '}
                        </p>
                      </ul>
                      {/* </div> */}
                    </figure>
                  </Col>
                  <Col>
                    <div className='patelco'>
                      {/* <h1>Case Study:</h1> */}

                      {/* <h2>
                <i>for</i> &nbsp;Patelco Credit Union
              </h2> */}

                      <img src={vrWalk} alt='vr Walk' />
                    </div>
                  </Col>
                </Row>
              </Container>
              <Container>
                <h2 className='case__section-title  bg-white'>Challanges</h2>

                <p>
                  The NHS sought an innovative way to engage young people and
                  raise awareness about the diverse range of careers available
                  within the health and care sectors. Beyond the traditional
                  perceptions of doctors and nurses, the NHS wanted to showcase
                  other essential roles such as administration, technical
                  support, and facility maintenance.
                </p>
              </Container>
              {/* <Container>
                <h2 className='case__section-title  text-center'>Solution</h2>
                <p>
                  MXR collaborated with the NHS to create an immersive Virtual
                  Reality (VR) experience—a walkthrough of a typical hospital
                  environment. This interactive tool allowed young people to
                  explore various job roles within the NHS by virtually
                  interacting with avatars representing a wide range of
                  professionals, including:
                </p>
                • Receptionist • Doctor • Nurse • Handyman • Electrician • IT
                Specialist
              </Container> */}
              <Container className='my-5'>
                <h2 className='case__section-title '>Solution</h2>
                <p className=''>
                  MXR collaborated with the NHS to create an immersive Virtual
                  Reality (VR) experience—a walkthrough of a typical hospital
                  environment. This interactive tool allowed young people to
                  explore various job roles within the NHS by virtually
                  interacting with avatars representing a wide range of
                  professionals, including:
                </p>

                <Row className='mt-4'>
                  <Col md={4} className='mb-4'>
                    <Card className='text-center'>
                      <Card.Body>
                        <FaUserMd
                          className='nhsIcons'
                          size={50}
                          color='#1292ee'
                        />{' '}
                        {/* Icon for Receptionist */}
                        <Card.Title>Receptionist</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={4} className='mb-4'>
                    <Card className='text-center'>
                      <Card.Body>
                        <FaStethoscope
                          className='nhsIcons'
                          size={50}
                          color='#1292ee'
                        />{' '}
                        {/* Icon for Doctor */}
                        <Card.Title>Doctor</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={4} className='mb-4'>
                    <Card className='text-center'>
                      <Card.Body>
                        <FaUserNurse
                          className='nhsIcons'
                          size={50}
                          color='#1292ee'
                        />{' '}
                        {/* Icon for Nurse */}
                        <Card.Title>Nurse</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={4} className='mb-4'>
                    <Card className='text-center'>
                      <Card.Body>
                        <FaToolbox
                          className='nhsIcons'
                          size={50}
                          color='#1292ee'
                        />{' '}
                        {/* Icon for Handyman */}
                        <Card.Title>Handyman</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={4} className='mb-4'>
                    <Card className='text-center'>
                      <Card.Body>
                        <FaLightbulb
                          className='nhsIcons'
                          size={50}
                          color='#1292ee'
                        />{' '}
                        {/* Icon for Electrician */}
                        <Card.Title>Electrician</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={4} className='mb-4'>
                    <Card className='text-center'>
                      <Card.Body>
                        <FaLaptopCode
                          className='nhsIcons'
                          size={50}
                          color='#1292ee'
                        />{' '}
                        {/* Icon for IT Specialist */}
                        <Card.Title>IT Specialist</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <p className=''>
                  Upon approaching an avatar, users could click on them to hear
                  a detailed explanation of their job role through video clips.
                  These clips also highlighted the different learning pathways
                  (e.g., T-levels, apprenticeships) that led to each career,
                  providing practical insights into how young people could
                  pursue similar roles in the future.
                </p>
              </Container>
              <Container>
                <h2 className='case__section-title mt-3'>Implementation</h2>
                <p className=''>
                  Once the project was completed, the NHS used the VR experience
                  at schools and career expos across the country. The
                  combination of cutting-edge VR technology and relevant content
                  drew significant attention. Young people were eager to try the
                  headsets, which offered them a first-hand look at the
                  diversity of career options within the NHS.
                </p>
              </Container>
              <Container className='video-container mt-4'>
                {/* <h2 className='case__section-title'>Watch the VR Experience</h2> */}
                <div className='nhsVideoSection'>
                  <video
                    className='nhsVideo'
                    src={vrVideo}
                    controls
                    allowFullScreen
                    title=' Hospital VR Experience Video'
                    autoPlay
                    loop
                  ></video>
                </div>
              </Container>
              <Container>
                <h2 className='case__section-title mt-3'>Results</h2>

                <p className=''>
                  The VR walkthrough attracted a wide range of young audiences
                  who were often surprised by the variety of roles available in
                  the NHS beyond the medical professions. The immersive
                  experience gave them a clear understanding of career
                  opportunities, and it opened up conversations about
                  apprenticeships, T-levels, and other training pathways.
                  Follow-up resources guided interested participants to the NHS
                  Career Pathways for further exploration.
                </p>
                <p>
                  Feedback from both the NHS and young participants was
                  overwhelmingly positive. Many young people asked for more
                  information about various roles, and the NHS reported a
                  renewed interest in their career outreach efforts,
                  demonstrating the effectiveness of the VR solution in
                  capturing attention and delivering meaningful educational
                  content.
                </p>
              </Container>
              <Container>
                <h2 className=' case__section-title'>Conclusion </h2>
                <p>
                  MXR’s VR project for the NHS successfully delivered an
                  engaging, interactive solution that resonated with young
                  people. By combining immersive technology with educational
                  content, the NHS was able to showcase the breadth of career
                  opportunities available, driving greater awareness and
                  interest in health and care roles across the country.
                </p>
              </Container>
            </article>
          </main>
        </div>
      </Container>
      <Readytotalk discuss={readytotalk} />
    </>
  );
}

export default NhsCase;
