import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
import picture1 from '../../assets/icons/Process3.svg';
const Pictures = () => {
  return (
    <div className='mxr__pictures mxr__section '>
      <Container>
        <Row className='justify-content-md-center'>
          <Col>
            <img
              className='mxr__process-desktop  d-sm-block w-100 vr360Picture'
              src={picture1}
              alt='process'
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Pictures;
