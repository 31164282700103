import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
import { RiGraduationCapFill } from 'react-icons/ri';
import { FaThumbsUp } from 'react-icons/fa';
import { FaFaceGrinWide } from 'react-icons/fa6';

const statisticsData = [
  {
    percentage: '75%',
    text: 'Increase in knowledge retention',
    icon: RiGraduationCapFill,
  },
  {
    percentage: '90%',
    text: 'Increase in engagement with XR training',
    icon: FaThumbsUp,
  },
  {
    percentage: '275%',
    text: 'Increase in confidence',
    icon: FaFaceGrinWide,
  },
];

const KeyStats = () => {
  return (
    <div
      className='mxr__section insightSection'
      style={{ backgroundColor: '#f8faff' }}
    >
      <Container className='mt-5 mb-5'>
        <Row>
          <Col className='d-flex justify-content-md-center gap-5'>
            {statisticsData.map((stat, index) => (
              <div
                className='stat-item InsightStat
              '
                key={index}
              >
                {/* Render the icon component directly */}
                <stat.icon
                  className='mb-4'
                  style={{ color: '#1292ee', fontSize: '4rem' }}
                />
                <h3>{stat.percentage}</h3>
                <p>{stat.text}</p>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default KeyStats;
