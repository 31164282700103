import React from 'react';
import { Row, Col, Container } from 'react-bootstrap-v5';
import faqimg from './../../../assets/images/DinoWorld/faqImg5.png';
import Accordion from 'react-bootstrap/Accordion';

function Faqs() {
  return (
    <div className='mxr__benifits mxr__section mxr__hireus bg-light '>
      <Container>
        <h3 className='mxr__section-title_black text-center mb-5'>
          ALL INQUIRIES WELCOME
        </h3>
        <Row>
          <Col md={6} sm={12}>
            <img src={faqimg} alt='hjfh' />
          </Col>
          <Col md={6} sm={12}>
            <Accordion defaultActiveKey='0' className='mxr__section-faq'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header> What is DinoWorld?</Accordion.Header>
                <Accordion.Body>
                  DinoWorld is an Augmented Reality (AR) book that combines
                  immersive storytelling with cutting-edge technology. It allows
                  readers to explore the world of dinosaurs in a lifelike and
                  interactive environment.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='1'>
                <Accordion.Header>
                  How does the AR technology work in DinoWorld?
                </Accordion.Header>
                <Accordion.Body>
                  DinoWorld utilises AR technology to overlay virtual elements
                  onto the real world. By using a compatible device, readers can
                  see dinosaurs come to life on the pages of the book through
                  their device's camera.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='2'>
                <Accordion.Header>
                  Can I read DinoWorld without an AR device?
                </Accordion.Header>
                <Accordion.Body>
                  While the book can still be enjoyed as a traditional read, the
                  full immersive experience is achieved through the use of an AR
                  device. We recommend using a compatible smartphone or tablet
                  for the best experience.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='3'>
                <Accordion.Header>
                  Can I use DinoWorld offline?
                </Accordion.Header>
                <Accordion.Body>
                  While some features may require an internet connection for
                  updates or community engagement, the core AR experience in
                  DinoWorld can be enjoyed offline once the content is
                  downloaded to your device.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='4'>
                <Accordion.Header>
                  {' '}
                  Is DinoWorld suitable for all age groups?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, DinoWorld is designed to be enjoyed by readers of all
                  ages. The content is engaging and educational, making it
                  suitable for both younger readers and those interested in
                  paleontology.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='5'>
                <Accordion.Header>
                  Is DinoWorld available for free?{' '}
                </Accordion.Header>
                <Accordion.Body>
                  No, DinoWorld is not available for free. It is a premium
                  Augmented Reality (AR) book that offers a unique and immersive
                  experience. The pricing details can be found on our official
                  website or the platform where you plan to make the purchase.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='6'>
                <Accordion.Header>
                  Are there any free features or a trial version of DinoWorld?
                </Accordion.Header>
                <Accordion.Body>
                  Currently, we do not offer a free version or trial for
                  DinoWorld. However, you can explore reviews, previews, and
                  additional information on our website to get a sense of the
                  captivating experience that awaits you.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='7'>
                <Accordion.Header>
                  Are there hidden fees or in-app purchases after I buy
                  DinoWorld?
                </Accordion.Header>
                <Accordion.Body>
                  No, there are no hidden fees or in-app purchases after
                  purchasing DinoWorld. The price you pay for the book includes
                  full access to all its features.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='8'>
                <Accordion.Header>
                  I am a school/publisher/ distributor. How can I connect?
                </Accordion.Header>
                <Accordion.Body>
                  Please mail to team@mxr.in or whatsapp us at 00000000. We
                  shall get back in 24 hours
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Faqs;
