import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
import who from '../../assets/icons/whoweare/who.png';
import './whoweare.css';
import team2 from '../../assets/All-Images/image2.png';
import team3 from '../../assets/All-Images/image3.png';
import aboutWhere from '../../assets/All-Images/about5.png';
import dot from '../../assets/icons/dot-image.png';

const WhoWeAre = () => {
  return (
    <div className='mxr__whoweare ' style={{ backgroundColor: '#f8faff' }}>
      <Container>
        <Row className='justify-content-md-center'>
          <Col className='whoweImag' sm={6}>
            {/* <img src={who} alt="Who we are"/> */}
            <div className='about-img-sec'>
              <div className='about-image'>
                <img
                  className='aboutWhere'
                  src={aboutWhere}
                  alt='Team at work'
                />
              </div>
              {/* <div className='about-image'>
                <img src={team3} alt='Team at work' />
              </div>
              <div className='about-image'>
                <img src={team2} alt='Team at work' />
              </div> */}
            </div>
          </Col>
          <Col className='whoWEmargin' sm={6}>
            <div className='mxr__whoWeAre-content'>
              <h3 className='mxr__section-title'>WHO WE ARE</h3>
              <p>
                MXR was founded with a vision to lead in technology innovation,
                and we believe in working with you to create impactful,
                user-friendly solutions. Our team of experts, drawing on years
                of experience across key sectors like education, defence,
                healthcare, and construction, is dedicated to making your
                processes more efficient and engaging. We partner with you every
                step of the way to ensure our solutions are tailored to meet
                your goals and add true value to your organisation.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhoWeAre;
