import React from 'react';
import './aboutus.css';
import comp from '../../assets/icons/about-us-icons/Comprehensive3.svg';
import cost from '../../assets/icons/about-us-icons/Cost-effective3.svg';
import exper from '../../assets/icons/about-us-icons/Experienced-icon3.svg';
import rapid from '../../assets/icons/about-us-icons/Rapid-turn3.svg';
import team2 from '../../assets/All-Images/image2.png';
import team3 from '../../assets/All-Images/image3.png';
import aboutImg from '../../assets/All-Images/AboutUs22.jpg';
import dot from '../../assets/icons/dot-image.png';
const AboutUs = () => {
  return (
    <div className='mxr__section aboutSection'>
      <div className='about-us-section'>
        <div className='about-us-container'>
          <h2 className='mxr__section-title text-center'>
            Benefits of Partnership
          </h2>
          {/* <p>
            At MXR, we aim to be your technology partner, leveraging AR, VR, and
            Mixed Reality to transform your business
          </p> */}
          {/* <div className='backgroundDot'>
            <img src={dot} alt='background dot' className='dotimage' />
          </div> */}
          <div className='about-us-content'>
            <div className='about-us-image'>
              <img className='mxrAbout' src={aboutImg} alt='Team at work' />
            </div>
            {/* <div className='about-us-image'>
              <img src={team3} alt='Team at work' />
            </div>
            <div className='about-us-image'>
              <img src={team2} alt='Team at work' />
            </div> */}
            <div className='about-us-benefits'>
              {/* <h3 className='benefits-bottom'>Benefits of Partnership:</h3> */}
              <ul>
                <li className='abouticon'>
                  <img src={cost} alt='Icon' />
                  Cost-effective solutions
                </li>
                <li className='abouticon'>
                  <img src={comp} alt='Icon' />
                  Comprehensive consulting services
                </li>
                <li className='abouticon'>
                  <img src={rapid} alt='Icon' />
                  Rapid turnaround with a team of 30 software developers
                </li>
                <li className='abouticon'>
                  <img src={exper} alt='Icon' />
                  Experienced consultants in education and commercial training
                  sectors
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='external-image'></div>
      </div>
    </div>
  );
};

export default AboutUs;
