import React, { useEffect } from 'react';

function Contact() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
        script.charset = 'utf-8';
        script.type = 'text/javascript';
        document.body.appendChild(script);
    
        script.onload = () => {
          if (window.hbspt) {
            window.hbspt.forms.create({
              region: "eu1",
              portalId: "143987629",
              formId: "d03567c0-89e4-4528-80ac-39e94d53dc76",
              target: "#hubspotForm"
            });
          }
        };
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);
    
      return <div id="hubspotForm"></div>;
}

export default Contact