import React from 'react';
import { Row, Col, Container } from 'react-bootstrap-v5';

function About(props) {
    console.log(props,"propsprops")
  return (
    <div className='mxr__benifits mxr__section mxr__hireus'>
      <Container>
        {props.data.map((list, index) => (
          <>
            <Row key={`benefit-${index}`}>
              <Col md={6} sm={12}>
                {list.images}
              </Col>
              <Col md={6} sm={12}>
                <h3 className='mxr__section-title'>{list.heading}</h3>
                {list.pharagraphs.map((read, index) => (
                  <p key={`benefitp-${index}`}>{read}</p>
                ))}
              </Col>
            </Row>
          </>
        ))}
      </Container>
    </div>
  );
}

export default About;
