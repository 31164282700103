import React from "react";
import "./advance.css";
import { Container, Row, Col } from "react-bootstrap-v5";
import { FaSearch, FaChartLine, FaIdCardAlt } from "react-icons/fa";

const Advance = () => {
  return (
    <div className="keyFeatures">
      <Container>
        <h3 className="mxr__section-title textUppercase text-center">How Does It Work?</h3>
        <Row className="justify-content-md-center">
          <Col sm={9}>
            <p>
              Creating a 360 Virtual Tour is simple: upload your 360/VR photos,
              edit and enhance them with interactive elements, and share your
              tours easily. Users can navigate through the space, interact with
              various elements, and get a realistic feel of the environment.
            </p>
          </Col>
        </Row>

        <Row>
          <Col md={4} sm={12}>
            <div className="bg_3steps_icon">
              <FaSearch size={40} />
            </div>
            <h5>CREATE</h5>
            <p>Add interactive hotspots and build immersive experiences.</p>
          </Col>
          <Col md={4} sm={12}>
            <div className="bg_3steps_icon">
              <FaChartLine size={40} />
            </div>
            <h5>ANALYTICS</h5>
            <p>
              Understand and optimise user behaviour with detailed insights.
            </p>
          </Col>
          <Col md={4} sm={12}>
            <div className="bg_3steps_icon">
              <FaIdCardAlt size={40} />
            </div>
            <h5>DEMO</h5>
            <p>
              Allow customers to explore your space in a tactile, immersive
              environment.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Advance;
