import React from 'react';
import { Row, Col, Container } from 'react-bootstrap-v5';
import { FaDesktop, FaImage, FaTv, FaSun } from 'react-icons/fa';
import { MdOutlineSlowMotionVideo } from 'react-icons/md';
import xrimage2 from '../../assets/images/insightxr2.png';
import xrimage3 from '../../assets/images/insightxr3.png';

function Services() {
  return (
    <div className='mxr__capabilities mxr__section white_bg_point'>
      <Container>
        <h3
          className='textUppercase mxr__section-title '
          style={{ textAlign: 'center' }}
        >
          The XR management platform for enterprise
        </h3>
        <p className='text-center'>
          Implement cutting-edge XR solutions throughout your organisation using
          our efficient and secure XR management platform.
        </p>
        <Row className='mt-5 mb-5'>
          <Col
            md={6}
            sm={12}
            className='bottomMargin justify-content-md-center'
          >
            <div>
              <img
                className='xrImage2'
                src={xrimage2}
                alt='image'
                style={{ width: '400px' }}
              />
            </div>
          </Col>
          <Col
            md={6}
            sm={12}
            className='bottomMargin justify-content-md-center'
          >
            <div>
              <img
                className='xrImage3'
                src={xrimage3}
                alt='image'
                style={{ width: '400px' }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Services;
