import React from 'react';
import { Row, Col, Container } from 'react-bootstrap-v5';
import solution from '../../assets/images/Platform2.png';

function Solution(props) {
  return (
    <div
      className='mxr__benifits mxr__section mxr__hireus'
      style={{ backgroundColor: '#f8faff' }}
    >
      <Container>
        <>
          <Row className='justify-content-md-center'>
            <Col sm={7} >
              <h3 className='mxr__section-title textUppercase' style={{textAlign: 'left'}}>
                What is Platform Development and How Does It Work?
              </h3>

              <p>
                Platform development involves creating comprehensive systems
                that integrate multiple functionalities and data sources into a
                unified interface. These platforms can manage, analyse, and
                utilise data to streamline processes and enhance
                decision-making. By leveraging cutting-edge technologies, our
                platforms provide robust solutions tailored to your specific
                requirements.
              </p>
            </Col>
            <Col sm={5} style={{ textAlign: 'end' }}>
              <img src={solution} alt='solution' />
            </Col>
          </Row>
        </>
      </Container>
    </div>
  );
}

export default Solution;
