import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';

import icon1 from '../../assets/images/research.svg';
import icon2 from '../../assets/images/proof.svg';
import icon3 from '../../assets/images/viable.svg';

const statisticsData = [
  {
    title: 'Research',
    text: 'Our R&D analysts and market research specialists conduct comprehensive, in depth research of your idea needed for its validation, defining the scope of work, framing the budget, and identifying all the technical constraints and risks. It is a fundamental part of the R&D process necessary for establishing technology excellence and the strong market potential of the future product',
    icon: icon1,
  },
  {
    title: 'Proof of Concept',
    text: 'Based on the previous research, we build an initial product prototype to establish the viability of the idea and solution, which is necessary for moving forward with the R&D process. We provide maximum flexibility during this process, where the client gets complete freedom of creativity accompanied with professional R&D consulting.',
    icon: icon2,
  },
  {
    title: 'Viable Product',
    text: 'Meta Extended Reality (MXR) is the right way to deliver the product to the market on time before the competitors do. It is necessary for further solution validation and quick market feedback. Our R&D engineers will create a minimum viable product based on your idea and solution prototypes, so that your solution can pass a battle test and get ready for further improvements',
    icon: icon3,
  },
];

const How = () => {
  return (
    // <div className="mxr__section" >
    <div className='statistics-section'>
      <Container>
        <h2 className='textUppercase mxr__section-title text-center'>
          How it works
        </h2>
        <Row>
          <div className='statistics-container'>
            {statisticsData.map((stat, index) => (
              <Col style={{ textAlign: 'left' }}>
                <div className='p-4' key={index}>
                  <img
                    src={stat.icon}
                    alt={`icon-${index}`}
                    className='stat-icon'
                  />
                  <h5 className='textUppercase'>{stat.title}</h5>
                  <p>{stat.text}</p>
                </div>
              </Col>
            ))}
          </div>
        </Row>
      </Container>
    </div>
    // </div>
  );
};

export default How;
