import React from 'react';
import { Container, Carousel } from 'react-bootstrap-v5';
import './trusted.css';
// import nhs from '../../assets/icons/about-us-icons/nhs.png';
import nhs from '../../assets/icons/about-us-icons/nhs3.jpeg';
// import citb from '../../assets/icons/about-us-icons/citb.png';
import mod from '../../assets/icons/about-us-icons/mod.png';
import skillhub from '../../assets/icons/about-us-icons/skillHub.png';
import hillingdon from '../../assets/icons/about-us-icons/hillingdon.png';
import andover from '../../assets/icons/about-us-icons/andover.png';
import bae from '../../assets/icons/about-us-icons/bae.png';
import british from '../../assets/icons/about-us-icons/britishgas2.jpg';

const TrustedBy = () => {
  return (
    <div className='mxr__section'>
      <Container>
        <div className='trusted-by'>
          <div className='trusted-by-header'>
            <hr />
            <h2 className='mxr__section-title'>Our UK-Trusted Partners</h2>
            <hr />
          </div>
          <Carousel interval={3000}>
            <Carousel.Item className='trustedItem'>
              <div className='trusted-by-logos'>
                <img className='trustLogos nhs' src={nhs} alt='nhs' />
                {/* <img className='trustLogos' src={citb} alt='citb' /> */}
                <img className='trustLogos mod' src={mod} alt='mod' />
                <img
                  className='trustLogos skill'
                  src={skillhub}
                  alt='skillhub'
                />
                <img
                  className='trustLogos hilli'
                  src={hillingdon}
                  alt='hillingdon'
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='trusted-by-logos'>
                <img
                  className='trustLogos andover'
                  src={andover}
                  alt='andover'
                />
                <img className='trustLogos bae' src={bae} alt='bae' />
                <img
                  className='trustLogos british'
                  src={british}
                  alt='british'
                />
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </Container>
    </div>
  );
};

export default TrustedBy;

// import React, { useRef } from 'react';
// import { Container } from 'react-bootstrap-v5';
// import './trusted.css';
// import nhs from '../../assets/icons/about-us-icons/nhs.png';
// import citb from '../../assets/icons/about-us-icons/citb.png';
// import mod from '../../assets/icons/about-us-icons/mod.png';
// import skillhub from '../../assets/icons/about-us-icons/skillHub.png';
// import hillingdon from '../../assets/icons/about-us-icons/hillingdon.png';
// import andover from '../../assets/icons/about-us-icons/andover.png';
// import bae from '../../assets/icons/about-us-icons/bae.png';
// import british from '../../assets/icons/about-us-icons/britishgas.png';

// const TrustedBy = () => {
//   const scrollRef = useRef(null);

//   const scroll = (direction) => {
//     const distance = 100;
//     const duration = 300;
//     if (direction === 'left') {
//       scrollRef.current.scrollBy({ left: -distance, behavior: 'smooth' });
//     } else {
//       scrollRef.current.scrollBy({ left: distance, behavior: 'smooth' });
//     }
//   };

//   return (
//     <div className='mxr__section'>
//       <Container>
//         <div className='trusted-by'>
//           <div className='trusted-by-header'>
//             <hr />
//             <h2 className='mxr__section-title'>Our UK-Trusted Partners</h2>
//             <hr />
//           </div>
//           <div className='trusted-by-container'>
//             <button
//               className='scroll-arrow left'
//               onClick={() => scroll('left')}
//             >
//               &#9664;
//             </button>
//             <div className='trusted-by-logos' ref={scrollRef}>
//               <img className='trustLogos' src={nhs} alt='nhs' />
//               <img className='trustLogos' src={citb} alt='citb' />
//               <img className='trustLogos' src={mod} alt='mod' />
//               <img
//                 className='trustLogos'
//                 src={skillhub}
//                 alt='skillhub'
//                 style={{ width: '180px' }}
//               />
//               <img className='trustLogos' src={hillingdon} alt='hillingdon' />
//               <img className='trustLogos' src={andover} alt='andover' />
//               <img className='trustLogos' src={bae} alt='bae' />
//               <img className='trustLogos' src={british} alt='british' />
//             </div>
//             <button
//               className='scroll-arrow right'
//               onClick={() => scroll('right')}
//             >
//               &#9654;
//             </button>
//           </div>
//         </div>
//       </Container>
//     </div>
//   );
// };

// export default TrustedBy;
