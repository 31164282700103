import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap-v5';
import Customer from '../../assets/images/Customer-centricity.svg';
import Project from '../../assets/images/ProjectM.svg';
import Time from '../../assets/images/TimeEffi.svg';
import anim from '../../assets/images/anim.gif';
import anim2 from '../../assets/images/anim2.gif';
import anim3 from '../../assets/images/anim3.gif';

const Why = () => {
  const initialCards = [
    {
      title: 'Students',
      imgSrc: anim,
      description: `
  From high school to college, students can enhance their preparation for board exams, entrance tests, and certifications.`,
    },
    {
      title: 'Educators',
      imgSrc: anim2,
      description: `Teachers and trainers can utilise ExamWizz to create and distribute customised learning content.`,
    },
    {
      title: 'Institutions',
      imgSrc: anim3,
      description: `Schools and educational institutions can integrate ExamWizz for a more structured and tech-driven learning approach.`,
    },
  ];

  return (
    <div
      className='mxr__features mxr__section'
      style={{ backgroundColor: 'white' }}
    >
      <Container>
        <h2 className='mxr__section-title text-center textUppercase'>
          Who Can Benefit from ExamWizz?
        </h2>

        <Row>
          {initialCards.map((card, index) => (
            <Col key={index} sm={12} md={4} lg={4}>
              <Card>
                <img src={card.imgSrc} alt={card.title} />
                <Card.Title className='textUppercase'>{card.title}</Card.Title>
                <Card.Body>
                  <Card.Text>{card.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Why;
