import React from 'react';
import { Carousel } from 'react-bootstrap-v5';
import '../../components/BannerVideo/style.css';
function AllBanner({ banners }) {
  return (
    <div>
      <Carousel fade>
        {banners.map((banner, index) => (
          <Carousel.Item key={index} interval={1000}>
            <video
              src={require(`../../assets/icons/banner/${banner.video}`)}
              autoPlay
              muted
              loop
              poster={require(`../../assets/icons/banner/bannerThumbnail/${banner.thumbnail}`)}
            />
            <Carousel.Caption>
              <div className='HomeTitle'>
                <h3 className='mxr__section-title'>{banner.heading}</h3>
                <p className='mb-3'>{banner.subHeading}</p>
                <br />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default AllBanner;
