import React from 'react';
import { Row, Col, Container } from 'react-bootstrap-v5';
import xr4 from '../../assets/images/insightxr1.png';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

function Expertise() {
  return (
    <div
      id=''
      className='mxr__benifits mxr__section mxr__hireus'
      style={{ backgroundColor: '#f8faff' }}
    >
      <Container>
        <Row className='justify-content-md-center p-5'>
          <Col className='immersiveaboutPara' md={6} sm={12}>
            <h3 className='textUppercase insight-title'>
              Take control of your XR deployments
            </h3>
            <div className='xr-features mb-3'>
              <div className='xr-feature-item mb-2'>
                <FaCheck />
                Deploy your library of apps, files, videos, and webXR links
              </div>
              <div className='xr-feature-item mb-2'>
                <FaCheck />
                Configure your devices with bundles of XR content, firmware, and
                settings
              </div>
              <div className='xr-feature-item mb-2'>
                <FaCheck />
                Remotely manage content versions and updates
              </div>
              <div className='xr-feature-item mb-2'>
                <FaCheck />
                Track device statuses, installed content, wifi connection,
                location and more
              </div>
              <div className='xr-feature-item mb-2'>
                <FaCheck />
                Control devices in realtime with a suite of remote commands
              </div>
              <div className='xr-feature-item mb-2'>
                <FaCheck />
                Activate Remote Screen Streaming to troubleshoot with complete
                clarity
              </div>
            </div>
            <div className='text-start'>
              <ScrollLink
                to='readytotalk'
                smooth={true}
                duration={400}
                className='btn btn-primary siteBtn'
              >
                Learn more about the product
              </ScrollLink>
              {/* <Link to='/' className='btn btn-primary siteBtn'>
                Learn more about the product
              </Link> */}
            </div>
          </Col>

          <Col
            md={6}
            sm={12}
            className='text-end'
            style={{ alignContent: 'center' }}
          >
            <div className='XrAbout'>
              <img
                src={xr4}
                alt='image'
                style={{ width: '600px', borderRadius: '20px' }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Expertise;
