import React from 'react';
import { Row, Col, Container } from 'react-bootstrap-v5';
import right from '../../assets/images/Right.png';

function Right() {
  return (
    <div className='mxr__benifits mxr__section mxr__hireus'>
      <Container>
        <Row className='justify-content-md-center mb-5'>
          <Col md={5} style={{alignContent: 'center'}}>
            <img src={right} alt='solution' />
          </Col>
          <Col md={7}>
            <h3 className='mb-3 mxr__section-title textUppercase'>
              Finding the Right Platform Solution
            </h3>
            <p>
              Choosing the right type of platform depends on your specific
              business requirements and the desired functionalities. Custom
              platforms offer tailored solutions that can integrate seamlessly
              with your existing systems, providing enhanced efficiency and
              effectiveness. Off-the-shelf solutions are suitable for general
              needs and provide a quick and cost-effective way to implement
              essential functionalities.
              <p></p>
              Understanding the various platform options and their applications
              can help organisations implement the most effective solutions,
              enhancing operational efficiency and decision-making. Custom
              platform development is reshaping business operations across
              industries, offering new possibilities for integrated,
              interactive, and impactful solutions.
            </p>
            <h4 className='mb-3 mt-5 textUppercase' style={{textAlign: 'center'}}>
              Manage Your Platform Development
            </h4>
            <p>
              Investing in platform development is a strategic decision. At MXR,
              we help you manage your platform development projects and ensure
              successful implementation
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Right;
