import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
import { FaDesktop, FaImage, FaSearch, FaSun } from 'react-icons/fa';
import img from '../../assets/images/Immersive-rooms4.jpg';
const Revolution = () => {
  return (
    <div
      className='mxr__capabilities mxr__section white_bg_point marginCapab ImmersiveWelcome'
      style={{ backgroundColor: '#ffffff' }}
    >
      <Container>
        <Row className='mt-5'>
          <Col md={6} sm={12}>
            <div className='immersiveAbout'>
              <img src={img} alt='no img ' />
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div>
              <h3
                className='textUppercase mxr__section-title '
                // style={{ textAlign: 'left' }}
              >
                Revolutionising Training and Education with Immersive Rooms
              </h3>
              <p>
                At MXR, we specialise in creating cutting-edge immersive rooms
                designed to enhance educational experiences and professional
                training. We understand the unique challenges and opportunities
                in the education and training sectors, and we provide
                comprehensive solutions that combine state-of-the-art hardware
                with custom-developed content. Our immersive rooms are more than
                just high-tech spaces—they're dynamic environments where
                learning comes to life, simulations become reality, and the
                boundaries between the physical and digital worlds blur.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Revolution;
