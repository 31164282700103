import React from 'react';
import { Row, Col, Container } from 'react-bootstrap-v5';
import Accordion from 'react-bootstrap/Accordion';

function Benifits(props) {
  return (
    <div className='mxr__benifits mxr__section mxr__hireus'>
      <Container>
        <Row className='my-4'>
          <Col md={6} sm={12}>
            {props.data.images}
          </Col>
          <Col md={6} sm={12}>
            <Accordion defaultActiveKey='0'>
              {props.data.accdata.map((list, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  key={`accordion-${index}`}
                >
                  <Accordion.Header>{list.heading}</Accordion.Header>
                  <Accordion.Body>
                    {list.pharagraphs.map((paragraph, pIndex) => (
                      <p key={`paragraph-${pIndex}`}>{paragraph}</p>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Benifits;
