import React from 'react';
import { Helmet } from 'react-helmet';
import { Innerbanner, Readytotalk } from '../../components';
import About from './About';
import exam1 from '../../assets/images/exam1.png';
import Why from './Why';
import Who from './Who';
import Applications from './Applications';
// import Expertise from "./Expertise";
// import Services from "./Services";
import video from '../../assets/images/videoXR.mp4';

const dataList = [
  {
    id: '1',
    heading: 'Introduction',
    images: (
      <img
        src={exam1}
        alt='image'
        style={{ width: '600px', borderRadius: '20px' }}
      />
    ),
    pharagraphs: [
      'ExamWizz by MXR is an advanced e-learning platform designed to revolutionise the way students prepare for exams. With a blend of cutting-edge technology, personalised learning paths, and interactive resources, ExamWizz makes education more accessible, engaging, and effective.',
    ],
  },
];

const bannerdata = {
  img: 'exam.png',
  title: 'ExamWizz: Your Smart Learning Partner',
  text: 'Prepare, Practice, and Excel with Our Advanced Exam Preparation Platform',
};

const readytotalk = {
  img: 'MR.jpg',
  title: 'Get Started with ExamWizz',
  text: 'Ready to take your learning experience to the next level? Sign up for ExamWizz today and access all the tools you need for success in one place.',
  button: 'Get in Touch',
};
function Examwizz() {
  return (
    <div>
      <Helmet>
        <title>Examwizz| MXR</title>
        <meta
          name='description'
          content='360° Virtual Reality (VR) Tours became a perfect marketing tool in real estate and design. It is particularly useful for those projects that are still in the development stage or under construction.'
        />
      </Helmet>
      <Innerbanner banner={bannerdata} />
      <About banner={dataList} />
      <Why />
      <Applications />
      <Who />
      <Readytotalk discuss={readytotalk} />
    </div>
  );
}

export default Examwizz;
