import React from 'react';

import { Innerbanner } from '../../components';
import { Helmet } from 'react-helmet';
import AllCaseStudies from './AllCaseStudy';
function AllCasePage() {
  const bannerdata = {
    img: 'allcaseStudyBanner.jpg',
    title: ' EXPLORE OUR CASE STUDIES',
    text: ' Discover how our innovative solutions have empowered organisations across various industries. Each case study showcases unique challenges and the tailored approaches that led to success.',
  };

  //   const readytotalk = {
  //     img: 'MR.jpg',
  //     title: 'Contact Us',
  //     text: 'Partner with MXR to design custom AI solutions that seamlessly integrate with your AR applications. Enhance user experiences, improve interactivity, and push the boundaries of what is possible. Elevate your AR projects with MXR today!',
  //     button: 'Get in Touch',
  //   };
  return (
    <div>
      <Helmet>
        <title>CASE STUDIES | MXR</title>
        <meta
          name='description'
          content='MXR is UK based software development company delivering reliable and in-budget solutions for AR, VR, MR, Web and Mobile App development to clients globally.'
        />
      </Helmet>

      <Innerbanner banner={bannerdata} />
      <AllCaseStudies />
    </div>
  );
}

export default AllCasePage;
