import React from "react";
import MeetingScheduler from "../../components/FormSceduler";

const App = () => {
  return (
    <div className="app">
      <div style={{ marginTop: "100px" }}>
        <MeetingScheduler />
      </div>
    </div>
  );
};

export default App;
    