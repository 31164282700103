// import { useState, useEffect, useRef } from 'react';
// import { NavLink } from 'react-router-dom';
// import Dropdown from './Dropdown';
// import Megamenu from './Megamenu';
// import { FaCaretDown } from 'react-icons/fa';

// const MenuItems = ({ items, depthLevel, onMenuItemClick }) => {
//   const [dropdown, setDropdown] = useState(false);
//   const ref = useRef();

//   useEffect(() => {
//     const handler = (event) => {
//       if (dropdown && ref.current && !ref.current.contains(event.target)) {
//         setDropdown(false);
//       }
//     };

//     document.addEventListener('mousedown', handler);
//     document.addEventListener('touchstart', handler);

//     return () => {
//       document.removeEventListener('mousedown', handler);
//       document.removeEventListener('touchstart', handler);
//     };
//   }, [dropdown]);

//   const handleClick = (e) => {
//     e.stopPropagation();

//     if (items.submenu || items.megamenu) {
//       e.preventDefault();
//       if (!dropdown) {
//         setDropdown(true);
//       } else {
//         setDropdown((prev) => !prev);
//       }
//     } else {
//       setDropdown(false);
//       setTimeout(onMenuItemClick, 0);
//     }
//   };
//   const onMouseEnter = () => {
//     window.innerWidth > 960 && setDropdown(true);
//   };

//   const onMouseLeave = () => {
//     window.innerWidth > 960 && setDropdown(false);
//   };

//   return (
//     <li
//       className='menu-items'
//       id={items.title}
//       ref={ref}
//       onMouseEnter={onMouseEnter}
//       onMouseLeave={onMouseLeave}
//     >
//       {items.megamenu ? (
//         <>
//           <div
//             className='testing'
//             aria-haspopup='menu'
//             aria-expanded={dropdown ? 'true' : 'false'}
//             onClick={handleClick}
//           >
//             <NavLink
//               className='nav-link'
//               to={items.link}
//               onClick={(e) => e.preventDefault()}
//             >
//               {items.title}
//             </NavLink>
//             {depthLevel > 0 ? <span>&raquo;</span> : <FaCaretDown />}
//           </div>
//           {dropdown && (
//             <Megamenu
//               depthLevel={depthLevel}
//               submenus={items.menusections}
//               dropdown={dropdown}
//               onMenuItemClick={onMenuItemClick}
//             />
//           )}
//         </>
//       ) : (
//         <>
//           {items.submenu ? (
//             <>
//               <div
//                 className='testing'
//                 aria-haspopup='menu'
//                 aria-expanded={dropdown ? 'true' : 'false'}
//                 onClick={handleClick}
//               >
//                 <NavLink
//                   className='nav-link'
//                   to='#'
//                   onClick={(e) => e.preventDefault()}
//                 >
//                   {items.title}
//                 </NavLink>
//                 {depthLevel > 0 ? <span>&raquo;</span> : <FaCaretDown />}
//               </div>
//               {dropdown && (
//                 <Dropdown
//                   depthLevel={depthLevel}
//                   submenus={items.submenu}
//                   dropdown={dropdown}
//                   onMenuItemClick={onMenuItemClick}
//                 />
//               )}
//             </>
//           ) : (
//             <NavLink className='nav-link' to={items.link} onClick={handleClick}>
//               {items.title}
//             </NavLink>
//           )}
//         </>
//       )}
//     </li>
//   );
// };

// export default MenuItems;

import { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import Dropdown from './Dropdown';
import Megamenu from './Megamenu';
import { FaCaretDown } from 'react-icons/fa';

const MenuItems = ({ items, depthLevel, onMenuItemClick }) => {
  const [dropdown, setDropdown] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [dropdown]);

  const handleClick = (e) => {
    e.stopPropagation();

    if (items.submenu || items.megamenu) {
      e.preventDefault();
      if (!dropdown) {
        setDropdown(true);
      } else {
        setDropdown((prev) => !prev);
      }
    } else {
      setDropdown(false);
      setTimeout(onMenuItemClick, 0);
    }
  };
  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  return (
    <li
      className='menu-items'
      id={items.title}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {items.megamenu ? (
        <>
          <div
            className='testing'
            aria-haspopup='menu'
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={handleClick}
          >
            <NavLink
              className='nav-link'
              to={items.link}
              onClick={(e) => e.preventDefault()}
            >
              {items.title}
            </NavLink>
            {depthLevel > 0 ? <span>&raquo;</span> : <FaCaretDown />}
          </div>
          {dropdown && (
            <Megamenu
              depthLevel={depthLevel}
              submenus={items.menusections}
              dropdown={dropdown}
              onMenuItemClick={onMenuItemClick}
            />
          )}
        </>
      ) : (
        <>
          {items.submenu ? (
            <>
              <div
                className='testing'
                aria-haspopup='menu'
                aria-expanded={dropdown ? 'true' : 'false'}
                onClick={handleClick}
              >
                <NavLink
                  className='nav-link'
                  to='#'
                  onClick={(e) => e.preventDefault()}
                >
                  {items.title}
                </NavLink>
                {depthLevel > 0 ? <span>&raquo;</span> : <FaCaretDown />}
              </div>
              {dropdown && (
                <Dropdown
                  depthLevel={depthLevel}
                  submenus={items.submenu}
                  dropdown={dropdown}
                  onMenuItemClick={onMenuItemClick}
                />
              )}
            </>
          ) : (
            <NavLink className='nav-link' to={items.link} onClick={handleClick}>
              {items.title}
            </NavLink>
          )}
        </>
      )}
    </li>
  );
};

export default MenuItems;
