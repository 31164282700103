import React from 'react';
import './ArStat.css';
import { Container } from 'react-bootstrap-v5';

import icon1 from '../../assets/icons/statVR/ImprovedSpatialAR.svg';
import icon2 from '../../assets/icons/statVR/IncreasedProductivityAR.svg';
import icon3 from '../../assets/icons/statVR/HigherAccuracyAR.svg';
import icon4 from '../../assets/icons/statVR/ReductionTrainingAR.svg';
const statisticsData = [
  {
    percentage: '47%',
    text: 'Improved spatial awareness and task efficiency in real-world settings',
    icon: icon1,
  },
  {
    percentage: '52%',
    text: 'Increased productivity through real-time guidance and digital overlays',
    icon: icon2,
  },
  {
    percentage: '40%',
    text: 'Higher accuracy and reduced downtime in hands-on technical tasks',
    icon: icon3,
  },
  {
    percentage: '60%',
    text: ' Reduction in training time and associated costs',
    icon: icon4,
  },
];

const ArStatistics = () => {
  return (
    <div className='mxr__section'>
      <div className='ARstatistics-section'>
        <Container>
          <h2 className='textUppercase mxr__section-title text-center'>
            Key statistics on the benefits of AR training
          </h2>
          <div className='ARstatistics-container'>
            {statisticsData.map((stat, index) => (
              <div className='stat-item' key={index}>
                <img
                  src={stat.icon}
                  alt={`icon-${index}`}
                  className='stat-icon'
                />
                <h3>{stat.percentage}</h3>
                <p>{stat.text}</p>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ArStatistics;
