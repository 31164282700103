import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
import { FaPlus, FaMinus } from 'react-icons/fa';
import './tabs.css';
import images from '../../assets/All-Images/E-learning-final2.jpg';

const Custom = () => {
  const [openIndex, setOpenIndex] = useState(0);

  const handleClick = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className=' mxr__section customE-learningSection'>
      <Container>
        <Row>
          <Col className=''>
            <img
              className='textimgcustom'
              src={images}
              alt='Custom E-Learning Design'
              // style={{ width: "100%", height: "auto" }}
            />
          </Col>
          <Col className='e-learningCustom' style={{ alignContent: 'center' }}>
            <div className='accordion-container'>
              {sections.map((section, index) => (
                <div
                  key={index}
                  className={`accordion ${openIndex === index ? 'active' : ''}`}
                  onClick={() => handleClick(index)}
                >
                  <div
                    className='accordion-header textUppercase'
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {section.header}
                    {openIndex === index ? <FaMinus /> : <FaPlus />}
                  </div>
                  <div className='accordion-content'>
                    <p>{section.content}</p>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const sections = [
  {
    header: 'Custom E-Learning Design',
    content:
      "In today's digital age, e-learning has become an essential component of education and training. At MXR, we specialise in designing bespoke e-learning content tailored to the unique needs of training providers, further education institutions, higher education bodies, and large organisations. Our e-learning solutions are aligned with UK curriculum standards and meet the requirements of awarding bodies.",
  },
  {
    header: 'What is E-Learning Design and How Does It Work?',
    content:
      'E-learning design involves creating engaging, interactive, and educational content delivered through digital platforms. Our approach integrates the latest technologies to enhance learning experiences, making education accessible, flexible, and effective. We ensure that our e-learning content is compatible with any Learning Management System (LMS) platform, providing seamless integration and ease of use.',
  },
  {
    header: 'Finding the Right E-Learning Solution',
    content:
      'Choosing the right type of e-learning solution depends on your specific educational or training requirements and the desired outcomes. Custom e-learning content offers tailored solutions that integrate seamlessly with your existing systems, providing enhanced learning experiences. Off-the-shelf solutions are suitable for general needs and provide a quick and cost-effective way to implement essential training. Understanding the various e-learning options and their applications can help organisations implement the most effective solutions, enhancing learning outcomes and operational efficiency. Custom e-learning design is reshaping education and training across industries, offering new possibilities for interactive, engaging, and impactful learning experiences.',
  },
  {
    header: 'Manage Your E-Learning Content',
    content:
      'Once we develop your e-learning content according to your specific requirements, you can easily manage and deploy it using your existing LMS. Our content is designed to be user-friendly and straightforward to implement, allowing you to focus on delivering quality education and training without the hassle of complex integrations. Transform your educational operations with our custom e-learning design solutions. Contact us today to discover how our bespoke e-learning content can drive success for your organisation.',
  },
];

export default Custom;
