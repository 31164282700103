import React from 'react';
import { Helmet } from 'react-helmet';
import { Innerbanner, Readytotalk } from '../../components';
import xrimg from '../../assets/images/xr3.jpg';
import About from './About';
import Expertise from './Expertise';
import Services from './Services';
import Applications from './Applications';
import Why from './Why';
import video from '../../assets/images/videoXR.mp4';
import Banner from './Banner';
import KeyStats from './KeyStats';
import Slide from './Slide';
// import Offer from './Offer';
// import Applications from './Applications';
import AllBanner from '../../components/BannerVideo/BannerVideo';
const dataList = [
  {
    id: '1',
    heading: 'Introduction',
    images: (
      <video
        src={video}
        alt='lady with vr set'
        style={{ width: '600px', borderRadius: '20px' }}
        autoPlay
        loop
        muted
      />
    ),
    pharagraphs: [
      'Learners forget 70% of training content within 24 hours and almost 90% within a month. So, how can you make your training unforgettable? By using immersive training. Our solutions revolutionize training by delivering real-time, virtual hard skills content specifically designed for high-risk industries. Whether you need custom scenarios created or want to learn how to develop, manage, and monetize your own XR training content, we’re here to support you every step.',
    ],
  },
];

// const bannerdata = {
//   img: "xrtemp.png",
//   //   img: "vr-360-banner.jpg",
//   title: "Step into the Future with XR Solutions",
//   text: "Unlock the Power of Immersive Technology with Our AR, VR, and MR Expertise",
// };
const bannerdata = {
  img: 'About3.png',
  title: 'THE COMPREHENSIVE MANAGEMENT PLATFORM FOR AR AND VR DEVICES',
  text: 'Streamline the deployment of XR at scale, enabling content distribution, user experience customisation, and remote troubleshooting—all from your desk.',
};

const readytotalk = {
  img: 'MR.jpg',
  title: 'Contact Us',
  text: 'Partner with MXR to develop custom AI-driven solutions that seamlessly integrate with your AR, VR, and MR environments. Enhance interactivity, optimise performance, and unlock new dimensions of user engagement with our cutting-edge XR technology.',
  button: 'Get in Touch',
};
function InsightXR() {
  return (
    <div>
      <Helmet>
        <title>InsightXR| MXR</title>
        <meta
          name='description'
          content='360° Virtual Reality (VR) Tours became a perfect marketing tool in real estate and design. It is particularly useful for those projects that are still in the development stage or under construction.'
        />
      </Helmet>
      <Banner banner={bannerdata} />
      <KeyStats />
      <About banner={dataList} />
      <Expertise />
      <Services />
      <Slide />
      <Applications />
      <Why />
      <Readytotalk discuss={readytotalk} />
    </div>
  );
}

export default InsightXR;
