import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
import './vision.css';
import timeline from '../../../assets/images/mxrBox2.jpg';

const Aboutus = () => {
  return (
    <div className='mxr__section  p-0 pt-5'>
      <Container>
        <Row className='justify-content-md-center'>
          <Col sm={7} style={{ alignContent: 'center' }}>
            <h3 className='mxr__section-title textUppercase marginMobile'>
              About MXR
            </h3>
            <p>
              At MXR, we are committed to partnering with you to bring
              innovative technology solutions that transform education,
              training, and business processes. Our expertise spans Augmented
              Reality (AR), Virtual Reality (VR), Mixed Reality, Generative AI,
              Platform Development, E-Learning, and Website & App Development.
              We work alongside you to design solutions that fit your unique
              needs, align with industry standards, and deliver real, measurable
              results for your organisation.
            </p>
          </Col>

          <Col sm={5}>
            <img
              className='aboutMXRimg'
              src={timeline}
              alt=''
              style={{ height: '500px' }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Aboutus;
