import React from 'react';
import mission from '../../assets/images/mission.png';
import vision from '../../assets/images/Our-vission.png';
import { FaCheck } from 'react-icons/fa';
import { Row, Col } from 'react-bootstrap-v5';

const WhyPartnerUs = () => {
  return (
    <div className='mxr__section mxr__partnerSec bg-light'>
      <div className='container'>
        <Row className='justify-content-md-center'>
          <Col lg={5}>
            <img
              src={vision}
              alt='Why Partner with US'
              className='missionimg'
            />
          </Col>
          <Col lg={7} style={{ alignContent: 'center' }}>
            <h2 className='mxr__section-title align-left textUppercase'>
              Our Vision
            </h2>
            <p>
              Our vision is to be your leading technology partner, empowering
              your organisation through innovative solutions that transform
              learning, training, and operations. We aim to create experiences
              that drive success in today’s fast-evolving digital landscape.
            </p>
          </Col>
        </Row>

        <Row className='justify-content-md-center'>
          <Col lg={7} style={{ alignContent: 'center' }}>
            <h2 className='mxr__section-title align-left textUppercase'>
              Our Mission
            </h2>
            <p>
              Our mission is simple: to help you succeed. We work with you to
              design and implement solutions in AR, VR, Mixed Reality, and AI
              that ensure you and your organisation reach your goals. By
              partnering with you, we create technology that transforms
              education, training, and business processes, delivering solutions
              that maximise value and achieve outstanding results for your team.
            </p>
          </Col>
          <Col lg={5} style={{ textAlign: 'right' }}>
            <img
              src={mission}
              alt='Why Partner with US'
              className='missionimg'
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default WhyPartnerUs;
