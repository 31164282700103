import React from 'react';
import { Row, Col } from 'react-bootstrap-v5';
import images from '../../assets/icons/benifits/ar122.png';
import AR from '../../assets/icons/benifits/arBuilding.png';

const About = () => {
  return (
    <div className='mxr__section mxr__partnerSec'>
      <div className='partnership-section'>
        <div className='container'>
          <Row className='justify-content-md-center mt-5 '>
            <Col lg={5}>
              <img
                className='arAboutImg'
                src={images}
                alt='Why Partner with US'
              />
            </Col>
            <Col lg={7} style={{ alignContent: 'center' }}>
              <h3 className='textUppercase mt-0'>
                What is Augmented Reality Training
              </h3>
              <p>
                Augmented Reality (AR) has become a major technological
                advancement in recent years, significantly impacting various
                sectors such as education, healthcare, and business. AR enhances
                the real world by overlaying digital information onto physical
                environments, thus improving the efficiency and effectiveness of
                training and increasing engagement and productivity.
              </p>
            </Col>
          </Row>
          <Row className='justify-content-md-center mt-5'>
            <Col lg={7} style={{ alignContent: 'center' }}>
              <h3 className='textUppercase'>
                What is Augmented Reality and how does it work
              </h3>
              <p>
                Augmented Reality (AR) blends digital elements with the physical
                world, enhancing the users perception of reality. Unlike Virtual
                Reality (VR), which creates a fully immersive digital
                environment, AR superimposes digital information—such as images,
                sounds, and text—onto the real world. This is achieved through
                devices like smartphones, tablets, and AR glasses. AR technology
                uses sensors, cameras, and algorithms to detect the physical
                environment and overlay digital information seamlessly. Users
                interact with this augmented information in real time, allowing
                for interactive and engaging experiences.
              </p>
            </Col>
            <Col lg={5}>
              <img className='arAboutImg2' src={AR} alt='Why Partner with US' />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default About;
