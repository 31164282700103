import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, NavLink } from 'react-bootstrap-v5';
// import banner from '../../assets/images/Innerbanner/event2.png';
import './event.css';
import event1 from '../../assets/images/event1.jpg';
import { Innerbanner } from '../../components';
import event2 from '../../assets/images/event2.jpg';

const events = [
  {
    title: 'MXR Virtual Innovation Summit',
    date: 'October 12',
    day: 'Wednesday',
    time: '8:00 AM',
    location: 'Virtual Event',
    description:
      "Join us for the first-ever virtual innovation summit at MXR, where we'll discuss cutting-edge technologies, innovations, and how they can help transform the industry.",
    category: 'Community Events',
  },
  {
    title: 'MXR VR & AR Workshop',
    date: 'October 19',
    day: 'Friday',
    time: '10:00 AM',
    location: 'Virtual Event',
    description:
      "Join us for an in-depth VR & AR workshop at MXR, where we'll learn how to create immersive experiences using AR and VR technology.",
    category: 'Technology Workshops',
  },
  {
    title: 'MXR Mixed Reality Expo',
    date: 'October 26',
    day: 'Sunday',
    time: '9:00 AM',
    location: 'Virtual Event',
    description:
      "Experience the latest innovations in mixed reality at MXR, where we'll showcase our VR and AR products and discuss how they can help transform the industry.",
    category: 'Technology Events',
  },
  {
    title: 'MXR AI & Machine Learning Workshop',
    date: 'November 2',
    day: 'Thursday',
    time: '3:00 PM',
    location: 'Virtual Event',
    description:
      "Join us for an in-depth AI & Machine Learning workshop at MXR, where we'll learn how to create powerful AI solutions using cutting-edge technology.",
    category: 'Technology Workshops',
  },
];
const bannerdata = {
  img: 'event2.png',
  title: 'Tech Events & Industry Insights',
  text: '  Stay Ahead with Our Conferences, Webinars, and Networking Opportunities',
};

const Event = () => {
  return (
    <div>
      <React.Fragment>
        <Helmet>
          <title>Events</title>
        </Helmet>
        <Innerbanner banner={bannerdata} />

        {/* <div className='banner-container'>
          <img src={banner} alt='Banner' className='banner-image' />
          <div className='banner-text'>
            <h1>Tech Events & Industry Insights</h1>
            <p className='bannerSubheading mt-5'>
              Stay Ahead with Our Conferences, Webinars, and Networking
              Opportunities
            </p>
          </div>
        </div> */}
        <Container>
          <Row className='mt-5 mb-5'>
            <h1 style={{ borderBottom: '1px solid grey' }}>UPCOMMING EVENTS</h1>
            <Col sm={7}>
              {events.map((event, index) => (
                <Row className='event mt-5'>
                  <Col sm={3}>
                    <div className='day-column'>
                      <h4 className='month-name'>{event.date}</h4>
                      <p className='day-name'>{event.day}</p>
                    </div>
                  </Col>
                  <Col sm={9}>
                    <div className='title-column'>
                      <div className='event-title'>
                        <h3 className='title-name'>{event.title}</h3>
                      </div>
                      <div className='event-details'>
                        <ul className='event-details-list'>
                          <li className='event-details-item'>
                            <div>{event.location}</div>
                          </li>
                          <li className='event-details-item'>
                            <div>{event.time}</div>
                          </li>
                        </ul>
                      </div>
                      <div className='event-description'>
                        <p>{event.description}</p>
                      </div>
                      {/* <div className="event-category">
                    <div>
                      <span className="event-category-title">Category:</span>
                      <a className="event-category-name" href="#">
                        {event.category}
                      </a>
                    </div>
                  </div> */}
                    </div>
                  </Col>
                </Row>
              ))}
            </Col>
            <Col
              sm={5}
              className='text-center
            '
            >
              <div>
                <img src={event2} alt={'event image'} className='eventimg' />
                <img src={event1} alt={'event image'} className='eventimg' />
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  );
};
export default Event;
