import React from 'react';
import { Row, Col, Container } from 'react-bootstrap-v5';

function About(props) {
  return (
    <div
      className='mxr__benifits mxr__section mxr__hireus'
      style={{ marginBottom: '-250px' }}
    >
      <Container>
        {props.banner.map((list, index) => (
          <>
            <Row className='justify-content-md-center'>
              <Col md={6} sm={12}>
                <div className='ExamAbout' style={{ height: '600px' }}>
                  {list.images}
                </div>
              </Col>
              <Col className='immersiveaboutPara' md={6} sm={12}>
                <h3 className='mxr__section-title textUppercase'>
                  {list.heading}
                </h3>
                {list.pharagraphs.map((read, index) => (
                  <p key={`benefitp-${index}`}>{read}</p>
                ))}
              </Col>
            </Row>
          </>
        ))}
      </Container>
    </div>
  );
}

export default About;
