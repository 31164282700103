import React from 'react';
import './MrStat.css';
import { Container } from 'react-bootstrap-v5';

import icon1 from '../../assets/icons/statVR/decisionMakingMR.svg';
import icon2 from '../../assets/icons/statVR/IncreasedEngagementMR.svg';
import icon3 from '../../assets/icons/statVR/FasterLearningMR.svg';
import icon4 from '../../assets/icons/statVR/ReductionOperationalMR.svg';
const statisticsData = [
  {
    percentage: '45%',
    text: ' Enhanced situational awareness and decision-making in real-time scenarios',
    icon: icon1,
  },
  {
    percentage: '50%',
    text: ' Increased engagement and retention rates in technical and operational training',
    icon: icon2,
  },
  {
    percentage: '42%',
    text: 'Faster learning curves due to immersive and interactive environments',
    icon: icon3,
  },
  {
    percentage: '55%',
    text: 'Reduction in operational errors and safer on-site training',
    icon: icon4,
  },
];

const MrStatistics = () => {
  return (
    <div className='mxr__section'>
      <div className='MRstatistics-section'>
        <Container>
          <h2 className='textUppercase mxr__section-title text-center'>
            Key statistics on the benefits of MR training
          </h2>
          <div className='MRstatistics-container'>
            {statisticsData.map((stat, index) => (
              <div className='stat-item' key={index}>
                <img
                  src={stat.icon}
                  alt={`icon-${index}`}
                  className='stat-icon'
                />
                <h3>{stat.percentage}</h3>
                <p>{stat.text}</p>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default MrStatistics;
