import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap-v5";
import styled from "styled-components";
import image from "../../assets/images/Innerbanner/mr.jpg";

const Outerdiv = styled.div`
  background-image: url(${image});
  background-size: cover;
  background-position: center;
`;

const Banner = (props) => {
  return (
    <>
      <div className="internal_page_banner">
        <Outerdiv>
          <Container>
            <Row className="justify-content-md-center">
              <Col sm={9} className="">
                <h1 style={{ color: "black" }}>{props.banner.title}</h1>
                {props.banner.button && (
                  <>
                    <a className="mx-10 mxr__section-btn-white">
                      {props.banner.button}
                    </a>
                    <a className="mx-10 mxr__section-btn-white">Get In Touch</a>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </Outerdiv>
      </div>
      <p sm={9} style={{ color: "black" }}>
        {props.banner.text}
      </p>
      <img src={image} alt="dfgdfgfgh" />
    </>
  );
};

export default Banner;
