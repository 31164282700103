import React from 'react';
import {
  Innerbanner,
  //  Process,
  Readytotalk,
} from '../../components';
// import Design from '../vr/Design';
// import wdd from '../../assets/icons/intro/wdd.png';
// import DevelopmentService from './DevelopmentService'
// import TechnologyStacks from './TechnologyStack'
import { Helmet } from 'react-helmet';
import aiImage2 from '../../assets/All-Images/ai222.jpg';
import aiImage from '../../assets/All-Images/ai66.jpg';
import { Row, Col, Container } from 'react-bootstrap-v5';
import { RiRobot2Line } from 'react-icons/ri';
import { FaPuzzlePiece } from 'react-icons/fa6';
import { BiSolidAnalyse } from 'react-icons/bi';
import { MdJoinInner } from 'react-icons/md';
import './ai.css';
import { CgNotes } from 'react-icons/cg';
import { GrUserExpert } from 'react-icons/gr';
import { GrTechnology } from 'react-icons/gr';
import bot from '../../assets/images/bot4.png';
import { Link } from 'react-router-dom';
import ee from '../../assets/images/enhanced.svg';
import acc from '../../assets/images/acc.svg';
import plc from '../../assets/images/plc.svg';
import access from '../../assets/images/access.svg';
import ddi from '../../assets/images/decreaseTime.svg';
import education from '../../assets/images/tranformEducation.webp';
import parternshipImg2 from '../../assets/All-Images/aipic2.png';
import ai1 from '../../assets/All-Images/ai1.jpg';
import AllBanner from '../../components/BannerVideo/BannerVideo';
// import AiBanner from './AiBanner';

// const bannerdata = {
//   img: 'AI-Development3.jpg',
//   title: 'Generative AI',
//   text: 'Driving Innovation: Harnessing the Power of Generative AI for Cutting-Edge Solutions and Transformative Technologies',
// };
const banner = [
  {
    video: 'genVideo2.mp4',
    thumbnail: 'ai.jpg',
    heading: 'GENERATIVE AI',
    subHeading:
      'Driving Innovation: Harnessing the Power of Generative AI for Cutting-Edge Solutions and Transformative Technologies',
  },
];
const readytotalk = {
  img: 'MR.jpg',
  title: 'Get in Touch',
  text: 'Contact us today to learn more about how MXR can partner with your institution to revolutionise the way you deliver education.',
  button: 'Contact us',
};

function WDD() {
  return (
    <div>
      <Helmet>
        <title>Generative AI</title>
        <meta
          name='description'
          content='MXR is UK based software development company delivering reliable and in-budget solutions for AR, VR, MR, Web and Mobile App development to clients globally.'
        />
      </Helmet>
      {/* <Innerbanner banner={bannerdata} /> */}
      <AllBanner banners={banner} />

      <div className='mxr__benifits mxr__section mxr__hireus p-0'>
        <Container className='pt-5'>
          <Row>
            <Col md={6} sm={12}>
              <img
                className='mxr__process-desktop Ai-Image'
                src={aiImage}
                alt='image'
              />
            </Col>
            <Col md={6} sm={12}>
              <h3 className='mxr__section-title textUppercase'>
                What is Generative AI ?
              </h3>
              <p className='generativePara'>
                Generative AI is an advanced form of artificial intelligence
                that can create new content by learning from existing data. In
                the context of education, this means it can generate
                personalised learning materials, simulate real-world scenarios
                for interactive learning, and even automate administrative tasks
                like grading and lesson planning. By leveraging Generative AI,
                educational institutions can offer more tailored and engaging
                learning experiences that adapt to each student's unique needs
                and pace.
              </p>
            </Col>
          </Row>
          {/* <Row className='mb-5'>
            <Col md={6} sm={12}>
              <h3 className='mxr__section-title textUppercase'>
                Why Choose Generative AI?
              </h3>
              <p className='generativePara'>
                Generative AI offers the ability to create tailored educational
                content that meets the unique needs of every student. Whether
                it's generating personalised study materials, interactive
                simulations, or adaptive learning assessments, AI can enhance
                the educational experience by making it more engaging,
                accessible, and effective.
              </p>
            </Col>
            <Col md={6} sm={12} className='text-end mt-5'>
              <img
                style={{ borderRadius: '20px' }}
                className='mxr__process-desktop Ai-Image2'
                src={ai1}
                alt='image'
              />
            </Col>
          </Row> */}
        </Container>
      </div>

      <div
        className='mxr__capabilities mxr__section white_bg_point Aboutwhatwemargin generativeSectionAi2 '
        style={{ backgroundColor: '#f8faff' }}
      >
        <Container>
          <h2 className='mxr__section-title textUppercase text-center'>
            How Generative AI Enhances Further and Higher Education
          </h2>

          <Row className='mt-5'>
            <Col sm={5}>
              <img
                className='genAiImage'
                src={parternshipImg2}
                alt='Why choose us'
                style={{ height: '550px' }}
              />
            </Col>

            <Col sm={7}>
              <div>
                <ul>
                  <li className='abouticon'>
                    <img src={plc} alt='Icon' />
                    <div>
                      <h5>Personalised Learning Experiences</h5>
                      <p>
                        Generative AI can develop custom learning materials and
                        assessments that adapt to the needs of individual
                        students, ensuring each learner receives the support
                        they need to succeed.
                      </p>
                    </div>
                  </li>
                  <li className='abouticon'>
                    <img src={acc} alt='Icon' />
                    <div>
                      <h5>Automated Administrative Tasks</h5>
                      <p>
                        AI can handle time-consuming tasks such as marking
                        exams, grading assignments, and generating lesson plans,
                        freeing up educators to focus more on student
                        interaction and teaching.
                      </p>
                    </div>
                  </li>

                  <li className='abouticon'>
                    <img src={access} alt='Icon' />
                    <div>
                      <h5>Accessibility and Inclusivity</h5>
                      <p>
                        With AI-powered translation and content adaptation,
                        educational materials can be made accessible to a
                        diverse student body, including those with disabilities
                        or language barriers.
                      </p>
                    </div>
                  </li>
                  <li className='abouticon'>
                    <img src={ee} alt='Icon' />
                    <div>
                      <h5>Interactive and Engaging Content</h5>
                      <p>
                        AI can create immersive simulations, virtual labs, and
                        other interactive content that enhances student
                        engagement and deepens understanding of complex
                        concepts.
                      </p>
                    </div>
                  </li>
                  {/* <li className='abouticon'>
                    <img src={ddi} alt='Icon' />
                    <div>
                      <h5>Data-Driven Insights</h5>
                      <p>
                        MXR provides valuable analytics on student performance
                        and content engagement, helping you continuously improve
                        your educational materials.
                      </p>
                    </div>
                  </li> */}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='mxr__benifits mxr__section mxr__hireus p-0 tranformSection'>
        <Container>
          <Row>
            <Col md={6} sm={12}>
              <h3 className='mxr__section-title textUppercase partneringAiSection'>
                Partnering with MXR to Develop AI Solutions
              </h3>
              <p className='generativePara'>
                At MXR, we specialise in integrating Generative AI into
                educational platforms and Learning Management Systems (LMS). Our
                expertise allows us to collaborate with institutions to develop
                AI-supported platforms that streamline content creation,
                assessment, and lesson planning. Whether you're looking to
                enhance your existing LMS with AI capabilities or build a new
                platform from the ground up, MXR is your trusted partner in
                driving educational innovation.
              </p>
            </Col>
            <Col md={6} sm={12}>
              <img
                className='mxr__process-desktop Ai-ImagePartner'
                src={aiImage2}
                alt='image'
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className='mxr__capabilities mxr__section white_bg_point mt-2 chatbotSection'>
        <Container>
          <h3 className='mxr__section-title textUppercase text-center'>
            Case Study: APM Project Management Qualification Chatbot
          </h3>
          <Row className='justify-content-md-center'>
            <Col
              md={6}
              sm={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <div className='studybotDiv'>
                <img
                  className='studybot'
                  style={{ height: '450px' }}
                  src={bot}
                  alt='Study bot'
                />
              </div>
            </Col>
            <Col
              md={6}
              sm={12}
              style={{ alignItems: 'center', marginBottom: '185px' }}
            >
              <div>
                <p>
                  One of our standout projects is the development of a
                  Generative AI chatbot designed to assist students in passing
                  the APM Project Management Qualification. This AI-powered tool
                  provides real-time tutoring, personalised study guides, and
                  practice exams that closely mirror the actual testing
                  environment. The chatbot has significantly improved student
                  pass rates, demonstrating the powerful impact of integrating
                  AI into educational programmes.
                </p>
                <Link
                  to='/case-study/ai-chat-bot'
                  className='btn btn-primary siteBtn'
                >
                  Learn more
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div
        className="mxr__capabilities mxr__section white_bg_point"
        style={{ backgroundColor: "#f8faff" }}
      >
        <Container>
          <h3 className="mxr__section-title textUppercase text-center">
            What we offer
          </h3>
          <Row>
            <Col md={6} sm={12}>
              <div className="icon_leftside">
                <RiRobot2Line size={40} className="iconColor" />
              </div>
              <div>
                <h5 className="iconTitle textUppercase">Custom AI Chatbots</h5>
                <p>
                  We create AI-driven chatbots that can be embedded into
                  websites, apps, and LMS platforms, providing instant, accurate
                  support based on specific content or curriculum requirements.
                </p>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="icon_leftside">
                <FaPuzzlePiece size={40} className="iconColor" />
              </div>
              <div>
                <h5 className="iconTitle textUppercase">
                  AI-Enhanced Learning Solutions
                </h5>
                <p>
                  Our AI models can be integrated into VR, AR, and Mixed Reality
                  environments, enhancing interactivity and personalisation in
                  training and educational experiences.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
              <div className="icon_leftside">
                <BiSolidAnalyse size={40} className="iconColor" />
              </div>
              <div>
                <h5 className="iconTitle textUppercase">Advanced Analytics</h5>
                <p>
                  Leverage AI to gather insights and analytics on user
                  interactions, helping to optimise content delivery and improve
                  user engagement.
                </p>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="icon_leftside">
                <MdJoinInner size={40} className="iconColor" />
              </div>
              <div>
                <h5 className="iconTitle textUppercase">
                  Seamless Integration
                </h5>
                <p>
                  Whether you need AI to enhance customer service, streamline
                  operations, or support complex training programs, we ensure
                  seamless integration with your existing systems..
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}

      <Readytotalk discuss={readytotalk} />
    </div>
  );
}

export default WDD;
