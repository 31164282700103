import React from 'react';
import { Container, Card, Col, Row } from 'react-bootstrap-v5';
// import { Link } from 'react-router-dom';
// import { IoIosArrowForward } from 'react-icons/io';
import icon1 from '../../assets/icons/icon-02.svg';
import icon2 from '../../assets/icons/icon-05.svg';

function BenefitCard(props) {
  const initialCards = [
    {
      image: icon1,
      title: 'Engagement and Interaction ',
      description: `360° VR Tours allow users to explore spaces interactively, providing a tangible sense of the final product, especially useful for projects in development or under construction.`,
    },

    {
      image: icon2,
      title: 'Versatility ',
      description: `Ideal for various industries including real estate, automotive, hospitality, and education, these tours dynamically present spaces, enhancing marketing and sales efforts.`,
    },
  ];
  return (
    <div className='mxr__features mxr__section '>
      <Container>
        <h2 className='mxr__section-title textUppercase text-center'>
          Benefits of 360 Virtual Tours
        </h2>

        <Row className='justify-content-md-center tabColor'>
          {initialCards.map((card, index) => (
            <Col key={index} sm={12} md={6} lg={6}>
              <Card>
                <img src={card.image} alt={card.title} />
                <Card.Title className='textUppercase'>{card.title}</Card.Title>
                <Card.Body>
                  <Card.Text>{card.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default BenefitCard;
