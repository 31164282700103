import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
import { Link } from 'react-router-dom';
import './vr.css';
import vr from '../../assets/images/case-study2.jpg';

function VrCase() {
  return (
    <>
      <div className='mxr__capabilities mxr__section white_bg_point'>
        <Container style={{ marginBottom: '-120px' }}>
          <h3 className='mxr__section-title textUppercase text-center'>
            Case Study: Virtual Reality
          </h3>
          <Row className='justify-content-md-center'>
            <Col
              md={6}
              sm={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <div>
                <img className='vrCaseImg' src={vr} alt='vr img' />
              </div>
            </Col>
            <Col
              md={6}
              sm={12}
              style={{ alignItems: 'center', marginBottom: '185px' }}
            >
              <div>
                <p>
                  Virtual Reality (VR) is transforming industries with immersive
                  simulations and training. From medical education to real
                  estate, VR offers realistic, interactive environments for
                  enhanced learning, decision-making, and customer engagement.
                  These case studies highlight how VR technology is
                  revolutionising training, design, and experiential marketing
                  across various sectors, driving innovation and efficiency.
                </p>
                <Link to='/case-studies' className='btn btn-primary siteBtn'>
                  Learn more
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default VrCase;
