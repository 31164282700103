import React, { useState } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap-v5';
import './tabs.css';

import img1 from '../../assets/icons/e-learning/Training-Provide.jpg';
import img2 from '../../assets/icons/e-learning/further.jpg';
import img3 from '../../assets/icons/e-learning/higherEducation.jpg';
import img4 from '../../assets/icons/e-learning/Corporate-Training.jpg';
import img5 from '../../assets/icons/e-learning/awarding-bodies.jpg';
import img6 from '../../assets/icons/e-learning/new--technologies2.jpg';

const Custom = () => {
  const [selectedTab, setSelectedTab] = useState('Training Providers');

  const tabs = [
    {
      name: 'Training Providers',
      content:
        "We develop custom e-learning content for training providers, helping them deliver high-quality, interactive training programmes. Whether it's upskilling employees or providing industry-specific training, our solutions are designed to meet the unique needs of your organisation.",
      image: img1,
    },
    {
      name: 'Further Education',
      content:
        'Our e-learning content for further education institutions aligns with the UK curriculum, ensuring that learners receive relevant and comprehensive education. We incorporate multimedia elements, interactive assessments, and real-world scenarios to make learning engaging and effective.',
      image: img2,
    },
    {
      name: 'Higher Education',
      content:
        'In higher education, we create advanced e-learning modules that cater to the diverse needs of universities and colleges. Our content is designed to support both undergraduate and postgraduate programmes, offering a rich, interactive learning experience that enhances academic performance.',
      image: img3,
    },
    {
      name: 'Corporate Training',
      content:
        'For large companies, we provide custom e-learning solutions that cover a wide range of topics, from compliance training to leadership development. Our content is designed to be flexible, allowing for easy updates and integration with existing training programmes.',
      image: img4,
    },
    {
      name: 'Alignment with Awarding Bodies',
      content:
        'We ensure that our e-learning content meets the stringent requirements of awarding bodies, providing learners with recognised qualifications. Our team works closely with these bodies to ensure that all content is up-to-date, relevant, and aligned with industry standards.',
      image: img5,
    },
    {
      name: 'Integration of New Technologies',
      content:
        'We leverage the latest technologies to create innovative e-learning experiences. This includes incorporating virtual reality (VR), augmented reality (AR), and mixed reality (MR) to provide immersive learning experiences. Our content is designed to be compatible with any LMS platform, ensuring a smooth and efficient implementation.',
      image: img6,
    },
  ];

  return (
    <div className='mxr__whatwecando mxr__section vertical_tabs'>
      <Container>
        <h3 className='mxr__section-title textUppercase text-center'>
          Custom E-Learning Solutions
        </h3>
        <Tab.Container id='left-tabs-example' defaultActiveKey={tabs[0].name}>
          <Row>
            <Col md={6} sm={12}>
              <Nav variant='pills' className='flex-column'>
                {tabs.map((tab, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={tab.name}
                      onClick={() => setSelectedTab(tab.name)}
                      className=''
                    >
                      {tab.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col md={6} sm={12}>
              <Tab.Content>
                {tabs.map((tab, index) => (
                  <Tab.Pane eventKey={tab.name} key={index}>
                    <img
                      src={tab.image}
                      alt={tab.name}
                      className='img-fluid mb-3'
                      style={{ width: '100%', height: 'auto' }}
                    />
                    <p>{tab.content}</p>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </div>
  );
};

export default Custom;
