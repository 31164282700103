import React from 'react';
import { Container, Card, Col, Row } from 'react-bootstrap-v5';

const Expertise = () => {
  return (
    <div className='mxr__capabilities mxr__section white_bg_point'>
      <Container>
        <h2 className='mxr__section-title  text-center mb-5'>
          Our Expertise: Hardware Installation and Custom Content Development
        </h2>
        <Row className='gy-4'>
          {/* Immersive Room Installation */}
          <Col md={6}>
            <Card className='h-100'>
              <Card.Body>
                <Card.Title className='fw-bold'>
                  Immersive Room Installation
                </Card.Title>
                <Card.Text>
                  We design and install immersive rooms using the latest
                  technology, tailored to meet your specific needs. Key features
                  include:
                  <br />
                  <strong>Laser Touch Systems</strong>{' '}
                  <p>
                    These systems support multi-touch functionality, allowing
                    for dynamic and engaging experiences that respond to touch
                    and movement.{' '}
                  </p>
                  <strong>Custom Projector Support Solutions</strong>
                  <p>
                    We provide tailored solutions to ensure optimal projection
                    quality and coverage, maximising the immersive experience.{' '}
                  </p>
                  <strong>Launch Software Platforms</strong>
                  <p>
                    {' '}
                    We implement user-friendly platforms, such as InsightXR, to
                    allow you to control and manage immersive experiences easily
                    from a tablet or other devices.{' '}
                  </p>
                  <strong>Integrated Microsoft Teams Room (MTR) System</strong>
                  <p>
                    {' '}
                    Seamlessly integrate online collaboration tools for webinars
                    and virtual meetings.
                  </p>
                  <strong>Gamification Tools</strong>
                  <p>
                    Implement elements of game design to increase engagement and
                    motivation among learners, making training sessions more
                    interactive and enjoyable.{' '}
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Custom Training Content Development */}
          <Col md={6}>
            <Card className='h-100'>
              <Card.Body>
                <Card.Title className='fw-bold'>
                  Custom Training Content Development
                </Card.Title>
                <Card.Text>
                  We collaborate closely with your curriculum developers to
                  create custom training scenarios and immersive content that
                  align with your educational goals. Our team of experts works
                  to ensure that the content is engaging, informative, and
                  directly applicable to real-world situations. This service is
                  particularly beneficial for:
                  <br />
                  <strong>Curriculum Development</strong>
                  <p>
                    Aligning immersive content with T-levels, apprenticeships,
                    and other accredited programmes.
                  </p>
                  <strong>Simulations and Scenarios</strong>
                  <p>
                    Developing realistic simulations that prepare students for
                    specific challenges in their field.
                  </p>
                  <strong>Collective Training</strong>
                  <p>
                    Design multi-user experiences where participants can engage
                    in collective training exercises, enhancing teamwork and
                    collaborative problem-solving skills.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Sensory Rooms */}
          <Col md={12}>
            <Card className='h-100'>
              <Card.Body>
                <Card.Title className='fw-bold'>Sensory Rooms</Card.Title>
                <Card.Text>
                  We also design sensory rooms with interactive elements,
                  lighting, and sound for specific environments. These spaces
                  are effective for:
                  <br />
                  <strong>Special Educational Needs (SEN)</strong>
                  <p>
                    Providing a controlled environment where students with
                    special needs can explore and interact with the world in a
                    way that is tailored to their sensory requirements.
                  </p>
                  <strong>Therapeutic Settings</strong>
                  <p>
                    Creating spaces that aid in relaxation, focus, and emotional
                    regulation, beneficial in both educational and clinical
                    settings.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Expertise;
