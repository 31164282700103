import React from 'react';
import './loader.css';

const Loader = () => {
  return (
    <div class='loader'>
      <div class='circle'>
        <div class='dot'></div>
        <div class='outline'></div>
      </div>
      <div class='circle'>
        <div class='dot'></div>
        <div class='outline'></div>
      </div>
      <div class='circle'>
        <div class='dot'></div>
        <div class='outline'></div>
      </div>
      <div class='circle'>
        <div class='dot'></div>
        <div class='outline'></div>
      </div>
    </div>
  );
};

export default Loader;
