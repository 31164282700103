import React from 'react';

function Intro() {
  return (
    <>
      <div className='mxr__section IntroSection'>
        <div className='container '>
          <p className='intropara text-center'>
            At MXR, we are pioneers in harnessing the transformative power of
            Generative AI, VR, AR, and mixed reality to revolutionise the way
            educational content is delivered. Our deep expertise in the
            education sector and cutting-edge technology solutions empower
            institutions to create immersive, personalised learning experiences.
            Partner with us to stay ahead of the curve and unlock the full
            potential of your educational programmes through innovative,
            future-proof solutions.
          </p>
        </div>
      </div>
    </>
  );
}

export default Intro;
