import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
import './innerbanner.css';
import styled from 'styled-components';
import Logo from '../../assets/images/mxr_logo.png';

const Outerdiv = styled.div`
  background-image: url('assets/images/Innerbanner/${(props) =>
    props.$banner}');
`;

const Innerbanner2 = (props) => {
  return (
    <div className='internal_page_banner landing_page_banner'>
      <Outerdiv $banner={props.banner.img}>
        <Container>
          <Row>
            <Col>
              {' '}
              <img
                src={Logo}
                className='mxr__header-logo pt-3'
                alt='Meta Extended Reality'
              />
            </Col>
          </Row>
          <Row>
            <Col lg={8} md={5} className='left_internal_page'>
              <h1>{props.banner.title}</h1>
              <p>{props.banner.text}</p>
              {props.banner.button && (
                <>
                  <a className='mx-10 mxr__section-btn-white'>
                    {props.banner.button}
                  </a>
                  <a className='mx-10 mxr__section-btn-white'>Get In Touch</a>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Outerdiv>
    </div>
  );
};

export default Innerbanner2;
