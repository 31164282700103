import React, { useState } from 'react';
import { Container, Row, Col, Card, ListGroup, Form } from 'react-bootstrap-v5';
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaComment,
} from 'react-icons/fa';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import './contact.css';
import { Helmet } from 'react-helmet';
import Blogbanner from '../../components/blog/Blogbanner';
import { useFormik } from 'formik';
import { contactSchema } from '../../schema';
import contact from '../../assets/images/cta2-bg.png';
// import Innerbanner from "../../components/innerbanner/Innerbanner";

// const bannerdata = {
//   img: "About2.png",
//   title: "Your Technology Partner for Innovation and Excellence",
//   text: "Custom MXR experiences to empower your business with a new dimension of virtual reality, effectively used in multiple industries",
// };
import AllBanner from '../../components/BannerVideo/BannerVideo';
import ContactForm from '../../components/Contact/Contact';
const initialValues = {
  name: '',
  email: '',
  phoneNumber: '',
  address: '',
  text: '',
};

const Contact = () => {
  const [varified, setVarified] = useState(false);
  function onchange(value) {
    console.log('Captcha value:', value);
    setVarified(true);
  }

  const [msg, setMsg] = useState(null);
  const [errMsg, setErrMsg] = useState(null);

  const { values, errors, touched, handleSubmit, handleChange } = useFormik({
    initialValues: initialValues,
    validationSchema: contactSchema,
    onSubmit: async (values) => {
      console.log(values);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/user/contact`,
          values,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        setMsg(response.data.message);
        setErrMsg(null);
      } catch (error) {
        setMsg(null);
        setErrMsg(error.response.data.message);
      }
    },
  });
  const banner = [
    {
      video: 'contactus2.mp4',
      thumbnail: 'contact.jpg',
      heading: 'CONTACT MXR TODAY',
      subHeading:
        'Get in touch with us today. We are here to assist you with any inquiries or support you may need..',
    },
  ];
  return (
    <div className='mxr__contact'>
      <Helmet>
        <title>Contact Us | AR, VR and MR Technology | MXR</title>
        <meta
          name='description'
          content='MXR is UK based software development company delivering reliable and in-budget solutions for AR, VR, MR, Web and Mobile App development to clients globally.'
        />
      </Helmet>
      <div>
        {/* <div className="banner-container">
          <img
            src={contact}
            alt="Banner"
            className="banner-image"
          />
          <div className="banner-text">
            <h1>CONTACT MXR TODAY</h1>
            <p className="bannerSubheading mt-5">fhjgjgh</p>
          </div>
        </div> */}
        <AllBanner banners={banner} />

        <Container>
          <h3 className='mxr__section-title'>{''}</h3>
          <Row>
            <Col sm={12} md={4} className='left_instruction'>
              <Card>
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <div>
                      <FaMapMarkerAlt size={30} />
                    </div>
                    <div>
                      <h3 className='mxr__section-title'>Our Location</h3>
                      <p>
                        20-22 Wenlock Road, <br />
                        London, N1 7GU, England
                      </p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div>
                      <FaPhoneAlt size={30} />
                    </div>
                    <div>
                      <h3 className='mxr__section-title'>Call Us</h3>
                      <p>(+44) 20 7193 5407</p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div>
                      <FaEnvelope size={30} />
                    </div>
                    <div>
                      <h3 className='mxr__section-title'>Email Address</h3>
                      <p>team@mxr.ai</p>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
            <Col sm={12} md={8}>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Contact;
