import React from 'react';
import { Container, Nav, Tab, Row, Col } from 'react-bootstrap-v5';
import increase from '../../assets/icons/mixed/student2.jpg';
import contextual from '../../assets/icons/mixed/contextual22.jpg';
import explore from '../../assets/icons/mixed/moreMR2.jpg';
import guided from '../../assets/icons/mixed/Guided-Learning2.jpg';
import microsoft from '../../assets/icons/mixed/Microsoft-Hololens22.jpg';
import remote from '../../assets/icons/mixed/remote22.jpg';
import social from '../../assets/icons/mixed/Emotional-learning2.jpg';
import visiualisation from '../../assets/icons/mixed/visualisation22.jpg';
const MixedReality = () => {
  return (
    <div className='mxr__mixedreality mxr__section vertical_tabs'>
      <Container>
        <h3 className='mxr__section-title text-center'>
          MIXED REALITY APPLICATIONS
        </h3>
        <Tab.Container id='left-tabs-example' defaultActiveKey='training'>
          <Row>
            <Col md={6} sm={12}>
              <Nav variant='pills' className='flex-column'>
                <Nav.Item>
                  <Nav.Link eventKey='training'>
                    Increase Student Engagement in Classroom with MR
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='product'>
                    Visualisation & Collaboration
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='virtual'>
                    Social and Emotional Learning
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='visiual'>
                    Explore even more MR Devices
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='design'>Microsoft Hololens2</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='reality'>Remote assistance</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='web'>Guided Learning</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='data'>Contextual Data Delivery</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={6} sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey='training'>
                  <div>
                    <img
                      className='whatwecan'
                      src={increase}
                      alt='INCREASE STUDENT ENGAGEMENT IN CLASSROOM WITH MR'
                    />
                    <p>
                      Increase overall success and improve learning outcomes
                      when students learn with 3D technologies.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='product'>
                  <div>
                    <img
                      className='whatwecan'
                      src={visiualisation}
                      alt='VISUALISATION & COLLABORATION'
                    />
                    <p>
                      The ability to visualise new designs, layouts and
                      equipment in 3D at 1:1 scale on location. Identify and
                      check for clashes or take measurements.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='virtual'>
                  <div>
                    <img
                      className='whatwecan'
                      src={social}
                      alt='SOCIAL AND EMOTIONAL LEARNING'
                    />
                    <p>
                      Fostering social and emotional learning Mixed Reality
                      breaks through emotional barriers so students can
                      experience life from new perspectives. With this immersive
                      technology, you’ll create a setting for learners to
                      collaborate and give them access to once out of reach
                      experiences.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='visiual'>
                  <div>
                    <img
                      className='whatwecan'
                      src={explore}
                      alt='EXPLORE EVEN MORE MR DEVICES'
                    />
                    <p>
                      The best way to experience mixed reality is on the
                      industry’s most innovative devices, designed with MR in
                      mind.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='design'>
                  <div>
                    <img
                      className='whatwecan'
                      src={microsoft}
                      alt='MICROSOFT HOLOLENS2'
                    />
                    <p>
                      Hololens2 offers the most comfortable and immersive MR
                      experience available all with the reliability, security,
                      and scalability of Microsoft
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='reality'>
                  <div>
                    <img
                      className='whatwecan'
                      src={remote}
                      alt='REMOTE ASSISTANCE'
                    />
                    <p>
                      Empowering employees to work together by allowing
                      technicians hands free access to remote experts. Providing
                      technical guidance, remote auditing, screen sharing and
                      annotating.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='web'>
                  <div>
                    <img
                      className='whatwecan'
                      src={guided}
                      alt='GUIDED LEARNING'
                    />
                    <p>
                      Augmenting holographic instructions on to equipment to
                      allow step by step guidance, training and fault finding.
                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='data'>
                  <div>
                    <img className='whatwecan' src={contextual} alt='web' />
                    <p>
                      Access to real time data and information at the point of
                      need to improve operational efficiency. This could be
                      anything from equipment performance metrics temperature,
                      operating speed to logistic, safety or retail information.
                    </p>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </div>
  );
};

export default MixedReality;
