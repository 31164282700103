import React from "react";
import { Container, Row, Col } from "react-bootstrap-v5";
import { FaSearch, FaChartLine, FaIdCardAlt } from "react-icons/fa";
import { SlGraph } from "react-icons/sl";
import { GrIntegration } from "react-icons/gr";


const Feature = () => {
  return (
    <div className="keyFeatures">
      <Container>
        <h3 className="mxr__section-title text-center">
          InsightXR: YOUR PLATFORM DEVELOPMENT SOLUTION
        </h3>
        <Row className="justify-content-md-center">
          <Col sm={8}>
            <p>
              InsightXR is our comprehensive platform development system that
              simplifies your project management. With InsightXR, you can
              efficiently design, develop, and manage platforms tailored to your
              business needs.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-md-center mt-5 mb-5">
          <h4
            style={{ textAlign: "center", color: "white" }}
            className="mb-5 textUppercase text-center"
          >
            Key Benefits
          </h4>
          <Col md={3} sm={12} style={{ textAlign: "center" }}>
            <div className="bg_3steps_icon">
              <FaSearch size={40} />
            </div>
            <h5>Centralised Management</h5>
            <p>Easily oversee all development activities in one place.</p>
          </Col>
          <Col md={3} sm={12} style={{ textAlign: "center" }}>
            <div className="bg_3steps_icon">
              <GrIntegration size={40} />
            </div>
            <h5>Seamless Integration</h5>
            <p>Ensure smooth integration with existing systems.</p>
          </Col>
          <Col md={3} sm={12} style={{ textAlign: "center" }}>
            <div className="bg_3steps_icon">
              <SlGraph size={40} />
            </div>
            <h5>Real-Time Insights</h5>
            <p>Access instant metrics to evaluate platform performance.</p>
          </Col>
          <Col md={3} sm={12} style={{ textAlign: "center" }}>
            <div className="bg_3steps_icon">
              <FaChartLine size={40} />
            </div>
            <h5>Maximised ROI</h5>
            <p>
              Enhance operational outcomes with comprehensive reports and
              analytics.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col sm={9}>
            <p>
              Transform your business operations with our custom platform
              development solutions. Contact us today to discover how InsightXR
              can drive success for your organisation.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Feature;
