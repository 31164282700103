import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap-v5';
import Customer from '../../assets/images/Customer-centricity.svg';
import Project from '../../assets/images/ProjectM.svg';
import Time from '../../assets/images/TimeEffi.svg';

const Why = () => {
  const initialCards = [
    {
      title: 'Innovation-Driven',
      imgSrc: Project,
      description: `
  We leverage cutting-edge technology to deliver creative and effective solutions.`,
    },
    {
      title: 'Client-Centric Approach',
      imgSrc: Customer,
      description: `We work closely with our clients to understand their unique needs, ensuring our solutions align with their goals and deliver measurable results.`,
    },
    {
      title: 'Cost-Effective Excellence',
      imgSrc: Time,
      description: `With a team of skilled developers based in India, we provide high-quality solutions at competitive prices.`,
    },
  ];

  return (
    <div className='mxr__features mxr__section' style={{backgroundColor:'white'}}>
      <Container>
        <h2 className='mxr__section-title text-center textUppercase'>
          Why Choose MXR?
        </h2>

        <Row>
          {initialCards.map((card, index) => (
            <Col key={index} sm={12} md={4} lg={4}>
              <Card>
                <img src={card.imgSrc} alt={card.title} />
                <Card.Title className='textUppercase'>{card.title}</Card.Title>
                <Card.Body>
                  <Card.Text>{card.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Why;
