import React from "react";
import { CgNotes } from "react-icons/cg";
import { GrUserExpert } from "react-icons/gr";
import { GrTechnology } from "react-icons/gr";
import { Row, Col, Container } from "react-bootstrap-v5";
function Applications() {
  return (
    <div className="keyFeatures">
      <Container>
        <h3 className="mxr__section-title textUppercase text-center">
          Key Features of ExamWizz
        </h3>
        <Row className="justify-content-md-center">
          <Col md={4} sm={12}>
            <div className="bg_3steps_icon">
              <CgNotes size={40} />
            </div>
            <h5>Mock Tests and Simulations</h5>
            <p>
              Prepare with real-time exam simulations to boost confidence and
              performance.
            </p>
          </Col>
          <Col md={4} sm={12}>
            <div className="bg_3steps_icon">
              <GrUserExpert size={40} />
            </div>
            <h5>Progress Tracking and Reports</h5>
            <p>
              Keep track of learning milestones and get insights into strengths
              and weaknesses.
            </p>
          </Col>
          <Col md={4} sm={12}>
            <div className="bg_3steps_icon">
              <GrTechnology size={40} />
            </div>
            <h5>Expert-Led Tutorials</h5>
            <p>
              Learn from industry experts and subject matter professionals with
              on-demand video tutorials.
            </p>
          </Col>
          <Col md={4} sm={12}>
            <div className="bg_3steps_icon">
              <GrUserExpert size={40} />
            </div>
            <h5>Gamified Learning</h5>
            <p>
              Engage in quizzes, challenges, and earn rewards as you learn,
              keeping motivation high.
            </p>
          </Col>
          <Col md={4} sm={12}>
            <div className="bg_3steps_icon">
              <GrTechnology size={40} />
            </div>
            <h5>Collaborative Learning</h5>
            <p>
              Join study groups, interact with peers, and discuss topics through
              our in-platform community.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Applications;
